import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterBarComponent } from './filter-bar.component';
import {MAT_MENU_DEFAULT_OPTIONS, MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatCheckboxModule} from "@angular/material/checkbox";



@NgModule({
    declarations: [
        FilterBarComponent
    ],
    exports: [
        FilterBarComponent
    ],
    imports: [
        CommonModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule
    ],
    providers: [
      {provide: MAT_MENU_DEFAULT_OPTIONS, useValue: {overlayPanelClass: 'filter-menu'}}
    ]
})
export class FilterBarModule { }
