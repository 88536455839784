import {AfterViewInit, Component, Inject, Optional, ViewChild} from '@angular/core';
import {CDK_DRAG_PARENT, CdkDragHandle} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-drag',
  templateUrl: './drag.component.html',
  styleUrls: ['./drag.component.scss']
})
export class DragComponent implements AfterViewInit {
  @ViewChild(CdkDragHandle, {static: true}) handle?: CdkDragHandle;

  constructor(@Optional() @Inject(CDK_DRAG_PARENT) public cdk: any) {
  }

  ngAfterViewInit() {
    this.cdk._handles.length = 1;
    this.cdk._handles._results = [this.handle];
    this.cdk._handles.changes.next();
  }
}

