import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {LiveReceivingProcessLot} from "./live-receiving.swagger";

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  set filterBarToggleSubject$(value: BehaviorSubject<boolean>) {
    this._filterBarToggleSubject$ = value;
  }
  get filterBarToggleSubject$(): BehaviorSubject<boolean> {
    return this._filterBarToggleSubject$;
  }
  private _filterBarToggleSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public _filteredProcessLots: BehaviorSubject<LiveReceivingProcessLot[]>= new BehaviorSubject<LiveReceivingProcessLot[]>([]);
  constructor() {}

  /**
   * Toggle close or open value of true or false
   */
  toggle() {
    if (this._filterBarToggleSubject$.value) {
      return this._filterBarToggleSubject$.next(false)
    }
    return this._filterBarToggleSubject$.next(true);
  }

  /**
   * Toggle processLot is in Filtered list or not
   */
  checkFiltered(processLot:LiveReceivingProcessLot):boolean{
    let tes =this._filteredProcessLots.value?.find(l=>l.processLotId == processLot.processLotId)
    let isDisabled=processLot.processLotId ==  this._filteredProcessLots.value?.find(l=>l.processLotId == processLot.processLotId)?.processLotId;
    return isDisabled;
  }
}
