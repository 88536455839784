import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Cat2DialogService} from "@cat2/legacy-meta-cat";
import {prompt_confirmDelete, prompt_confirmPost, prompt_confirmStatusToComplete} from "../../../shared/strings";
import {MatMenuTrigger} from "@angular/material/menu";
import {MatDialog} from "@angular/material/dialog";
import {SelectOfficialCountComponent} from "../select-official-count/select-official-count.component";
import {ChangeManufactureDateComponent} from "../change-manufacture-date/change-manufacture-date.component";
import {AttributeDialogComponent} from "../attribute-dialog/attribute-dialog.component";
import {TicketsService} from "../../../core/services/tickets.service";

@Component({
  selector: 'app-grid-menu',
  templateUrl: './grid-menu.component.html',
  styleUrls: ['./grid-menu.component.scss']
})
export class GridMenuComponent implements OnInit {
  @Input() data: any;
  @Output() onDeleteData = new EventEmitter<any>();
  @Output() onUpdateData = new EventEmitter<any>();
  @Output() onUpdateAttribute = new EventEmitter();
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger | undefined;

  constructor(
    private _dialog: Cat2DialogService,
    private dialog: MatDialog,
    private ticketsService: TicketsService
  ) {
  }

  ngOnInit(): void {
  }

  onMoreMenuClick() {
    this.menuTrigger?.openMenu();
  }

  onComplete() {
    this._dialog.ShowYesNoDialog('',
      prompt_confirmStatusToComplete(this.data?.processLotId),
      (yes) => {
        if (yes) {
          this.data.status = "COMPLETE";
          this.onUpdateData.emit(this.data);
        }
      });
  }

  isCompleteDisable() {
    return !(this.data.status == 'HANGING' || this.data.status == 'ONLINE');
  }

  onAttribute() {
    this.dialog.open(AttributeDialogComponent, {data: this.data.manufactureDate}).afterClosed()
      .subscribe(result => {
        if (result) {
          this.ticketsService.updateAttribute({
            growerLot: this.data.growerLot,
            line: this.data.line,
            processLotGuid: this.data.guid,
            ticketGuid: result
          }).subscribe(() => this.onUpdateAttribute.emit());
        }
      })
  }

  onDateChange() {
    this.dialog.open(ChangeManufactureDateComponent, {
      data: {
        manufactureDate: this.data.manufactureDate
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.data.manufactureDate = result.newDate;
        this.data.dateChanged = true;//flag to change GQL mutation
        this.onUpdateData.emit(this.data);
      }
    })
  }

  onDelete() {
    this._dialog.ShowYesNoDialog('',
      prompt_confirmDelete(this.data?.processLotId),
      (yes) => {
        if (yes) {
          this.onDeleteData.emit(this.data.__id)
        }
      });
  }

  onPost() {
    this._dialog.ShowYesNoDialog('',
      prompt_confirmPost(this.data?.processLotId),
      (yes) => {
        if (yes) {
          this.data.status = "POSTED";
          this.onUpdateData.emit(this.data);
        }
      });
  }

  isPostAndCountDisable() {
    return this.data.status != 'COMPLETE';
  }

  isDeleteEnabled(): boolean {
    return this.data.status != 'SCHEDULED'
  }

  onSetOfficialCount() {
    this.dialog.open(SelectOfficialCountComponent, {
      data: {
        officialCount: this.data.officialCount,
        catchCount: this.data.catchCount,
        hangCount: this.data.hangCount,
        officialCountSource: this.data.officialCountSource
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.data.officialCountSource = result.source
        this.data.officialCount = result.count
        this.onUpdateData.emit(this.data);
      }
    })
  }
}
