import {Component, Input, OnInit} from '@angular/core';
import {LiveReceivingProcessLot} from "../../core/services/live-receiving.swagger";

@Component({
  selector: 'app-status-text',
  templateUrl: './status-text.component.html',
  styleUrls: ['./status-text.component.scss']
})
export class StatusTextComponent implements OnInit {

  @Input() status: string | undefined;
  constructor() { }

  ngOnInit(): void {
  }

}
