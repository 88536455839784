import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FilterService} from "../../core/services/filter.service";
import {
  FilterValues,
  PayloadOfFilterValues,
  ProcessLotsClient
} from "../../core/services/live-receiving.swagger";
import {AppStateService, Cat2NotificationService} from "@cat2/legacy-meta-cat";
import {DatePickerService} from "../../core/services/date-picker.service";
import {MatCheckbox, MatCheckboxChange} from "@angular/material/checkbox";
import {ProcessLotService} from "../../core/services/process-lot.service";

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {
  isShow: boolean= false;
  filtersList!: FilterValues;
  filterApplied: FilterValues=FilterValues.fromJS({classifications: [], growerLotIds: [], growerReferences: [], processLotIds: []});
  growerReferenceFilterTitle: string="Grower";
  isGrowerReferenceFiltered: boolean=false;
  processLotFilterTitle: string="Process Lot";
  isProcessLotFiltered: boolean=false;
  growerLotFilterTitle:string="Grower Lot";
  isGrowerLotFiltered:boolean=false;
  classificationFilterTitle:string="Class";
  isClassificationFiltered:boolean=false;
  @ViewChild('growerReference') public growerReference!:MatCheckbox;
  @ViewChild('processLot') public processLot!:MatCheckbox;
  @ViewChild('growerLot') public growerLot!:MatCheckbox;
  @ViewChild('classification') public classification!:MatCheckbox;

  constructor(
    private filterService: FilterService,
    public appStateService: AppStateService,
    public processLotsClient: ProcessLotsClient,
    private schedulerStateService: DatePickerService,
    private cat2NotificationService: Cat2NotificationService,
    private processLotService:ProcessLotService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.filterService.filterBarToggleSubject$.subscribe(
      (value) => {
        if (value) {
          this.isShow=true;
        } else {
          this.isShow=false;
        }
      }
    );
    this.getFilters();
  }
  close(){
    this.filterService.toggle();
  }
  getFilters(){
    this.appStateService.showLoadingOverlay();
    this.processLotsClient.getFilterValues(this.schedulerStateService.currentScheduleDate.value).subscribe(
      (response: PayloadOfFilterValues) => {
        this.filtersList = response.data
        this.appStateService.hideLoadingOverlay();
      },
      (error) => this.cat2NotificationService.error(error)
    );
  }
  filterByGrowerReference(event:MatCheckboxChange,growerReference:string){
    if(event.checked)
      this.filterApplied?.growerReferences.push(growerReference);
    else{
      const index: number = this.filterApplied?.growerReferences.indexOf(growerReference);
      if (index !== -1) {
        this.filterApplied?.growerReferences.splice(index, 1);
      }
    }
    this.filterProcessLots();
  }
  filterByProcessLotId(event:MatCheckboxChange,processLotId:string){
    if(event.checked)
      this.filterApplied?.processLotIds.push(processLotId);
    else{
      const index: number = this.filterApplied?.processLotIds.indexOf(processLotId);
      if (index !== -1) {
        this.filterApplied?.processLotIds.splice(index, 1);
      }
    }
    this.filterProcessLots();
  }
  filterByGrowerLotId(event:MatCheckboxChange,growerLotId:string){
    if(event.checked)
      this.filterApplied?.growerLotIds.push(growerLotId);
    else{
      const index: number = this.filterApplied?.growerLotIds.indexOf(growerLotId);
      if (index !== -1) {
        this.filterApplied?.growerLotIds.splice(index, 1);
      }
    }
    this.filterProcessLots();
  }
  filterByClassification(event:MatCheckboxChange,classification:string){
    if(event.checked)
      this.filterApplied?.classifications.push(classification);
    else{
      const index: number = this.filterApplied?.classifications.indexOf(classification);
      if (index !== -1) {
        this.filterApplied?.classifications.splice(index, 1);
      }
    }
    this.filterProcessLots();
  }
  filterProcessLots(){
    let filteredProcessLots=this.processLotService.processLotSubject$.value;
    let isFiltered:boolean=false;
    this.growerReferenceFilterTitle="Grower";
    if (this.filterApplied?.growerReferences != undefined && this.filterApplied.growerReferences.length!=0)
    {
      filteredProcessLots=filteredProcessLots.filter(l=>this.filterApplied?.growerReferences?.includes(l.grower?.growerReference!));
      this.growerReferenceFilterTitle=this.filterApplied?.growerReferences.length>1?this.filterApplied?.growerReferences[0]+"+1":this.filterApplied?.growerReferences[0];
      this.isGrowerReferenceFiltered=true;
      isFiltered=true;
    }
    this.processLotFilterTitle="Process Lot";
    if (this.filterApplied?.processLotIds != undefined && this.filterApplied.processLotIds.length!=0)
    {
      filteredProcessLots=filteredProcessLots.filter(l=>this.filterApplied?.processLotIds?.includes(l.processLotId!));
      this.processLotFilterTitle=this.filterApplied?.processLotIds.length>1?this.filterApplied?.processLotIds[0]+"+1":this.filterApplied?.processLotIds[0];
      this.isProcessLotFiltered=true;
      isFiltered=true;
    }
    this.growerLotFilterTitle="Grower Lot";
    if (this.filterApplied?.growerLotIds != undefined && this.filterApplied.growerLotIds.length!=0)
    {
      filteredProcessLots=filteredProcessLots.filter(l=>this.filterApplied?.growerLotIds?.includes(l.growerLot!));
      this.growerLotFilterTitle=this.filterApplied?.growerLotIds.length>1?this.filterApplied?.growerLotIds[0]+"+1":this.filterApplied?.growerLotIds[0];
      this.isGrowerLotFiltered=true;
      isFiltered=true;
    }
    this.classificationFilterTitle="Classification";
    if (this.filterApplied?.classifications != undefined && this.filterApplied.classifications.length!=0)
    {
      filteredProcessLots=filteredProcessLots.filter(l=>this.filterApplied?.classifications?.includes(l.classification?.classification!));
      this.classificationFilterTitle=this.filterApplied?.classifications.length>1?this.filterApplied?.classifications[0]+"+1":this.filterApplied?.classifications[0];
      this.isClassificationFiltered=true;
      isFiltered=true;
    }

    if(filteredProcessLots && isFiltered)
      this.filterService._filteredProcessLots.next(filteredProcessLots);
    else
      this.filterService._filteredProcessLots.next([]);
  }

  clearFilter(filter:string){
    switch(filter){
      case "growerReference":
        this.growerReference.checked=false;
        this.filterApplied.growerReferences=[];
        this.growerReferenceFilterTitle="Grower";
        this.isGrowerReferenceFiltered=false;
        break;
      case "processLot":
        this.processLot.checked=false;
        this.filterApplied.processLotIds=[];
        this.processLotFilterTitle="Process Lot";
        this.isProcessLotFiltered=false;
        break;
      case "growerLot":
        this.growerLot.checked=false;
        this.filterApplied.growerLotIds=[];
        this.growerLotFilterTitle="Grower Lot";
        this.isGrowerLotFiltered=false;
        break;
      case "classification":
        this.classification.checked=false;
        this.filterApplied.classifications=[];
        this.classificationFilterTitle="Class";
        this.isClassificationFiltered=false;
    }
    this.filterProcessLots();
  }
}
