import {Pipe, PipeTransform} from '@angular/core';
import {LiveReceivingProcessLot} from "../services/live-receiving.swagger";
import {HeightService} from "../services/height.service";
import {DateTimeService} from "../services/date-time.service";

@Pipe({
  name: 'processLotTransform',
  pure: false
})
export class ProcessLotTransformPipe implements PipeTransform {

  constructor(
    private heightService: HeightService,
    private dateTimeService: DateTimeService
  ) {
  }

  transform(
    processLot: LiveReceivingProcessLot,
    timeHeight: number,
    startDateTime:Date
  ): number {

    let date = new Date(processLot.processingStarted);
    // Get total pixels to be translated
    return this.heightService.getRangeHeightInPixels(
      startDateTime,
      date
    );
  }

}
