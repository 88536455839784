import {Injectable} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {LiveReceivingProcessLot, Shift} from "./live-receiving.swagger";
import {ProcessLotService} from "./process-lot.service";
import {DateTimeService} from "./date-time.service";
import {DateTime} from "luxon";


@Injectable({
  providedIn: 'root'
})
export class DragAndDropService {
  private _droppedProcessLot!: LiveReceivingProcessLot;
  shifts?: Shift[];


  constructor(
    private processLotsService: ProcessLotService,
    private dateTimeService: DateTimeService
  ) {
  }

  async dropListDropped($event: CdkDragDrop<any>) {
    // Get dropped data process lot
    this._droppedProcessLot = $event.item.data;
    await this.moveProcessLot(this._droppedProcessLot, $event)

  }


  async moveProcessLot(
    droppedProcessLot: LiveReceivingProcessLot,
    $event: CdkDragDrop<any>,
  ) {


    // Calculate the first shift start time
    const firstShift = this.shifts!.map(shift => shift.startDateTime).reduce((oldestDate, currentDate) => {
      return currentDate < oldestDate ? currentDate : oldestDate;
    });

     //update position in drag and drop arrays
     if ($event.container === $event.previousContainer) {
      moveItemInArray(
        $event.container.data,
        $event.previousIndex,
        $event.currentIndex
        );
      } else {
      // Set dropped process lot to new line
      console.log(this._droppedProcessLot)
      this._droppedProcessLot.line = Number($event.container.id);
      transferArrayItem(
        $event.previousContainer.data,
        $event.container.data,
        $event.previousIndex,
        $event.currentIndex
      );
    }

    // Get new start date depending on where the process lot is dropped based on preceding process lot
    let newStartDate: Date = this.getProcessLotDropLocationDate($event, droppedProcessLot, firstShift);

    // Check that the new start date not be before the first shift
    if (newStartDate < firstShift)
      newStartDate = firstShift;

    // Set the new dropped process lot date
    this.setDroppedProcessLotNewDate(droppedProcessLot, newStartDate);

    // Update process lot position via the API
    await this.processLotsService.updateProcessLotPositionAsync(this._droppedProcessLot);
  }

  private isDateToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }


  /**
   * Get the new start date of the dropped process lot
   * @param $event
   * @param droppedProcessLot
   * @param firstShift
   */
  getProcessLotDropLocationDate($event: CdkDragDrop<any>, droppedProcessLot: LiveReceivingProcessLot, firstShift: Date): Date {
    // Get current sorted process lots in current line from the Dom
    const currentSortedProcessLots: CdkDrag[] = $event.container.getSortedItems();

    // Check to see if there are no process lots in new dropped lin
    if (currentSortedProcessLots.length === 0) {
      if (this.isDateToday(droppedProcessLot.manufactureDate)) {
        // If it is today, return a date of now plus 5 minute buffer
        const currentTime: Date = DateTime.now().plus({minutes: 5}).toLocal().toJSDate();
        return new Date(droppedProcessLot.manufactureDate.getFullYear(),
          droppedProcessLot.manufactureDate.getMonth(),
          droppedProcessLot.manufactureDate.getDate(),
          currentTime.getHours(),
          currentTime.getMinutes()
        );
      }
      // If it is not today, return the start of the first shift
      return firstShift;
    }

    // Check to see if process lot was dropped at the beginning to determine how to get start date
    if ($event.currentIndex === 0) {
      // No preceding process lot, so get the process lot after
      const nextProcessLot: LiveReceivingProcessLot = currentSortedProcessLots[$event.currentIndex].data;
      // Get dropped process lot duration
      const droppedProcessLotDuration = this.dateTimeService.getDuration(
        droppedProcessLot.processingStarted, droppedProcessLot.processingFinished
      )
      // Return the time of the next process lot minus the dropped lot duration
      return this.dateTimeService.subtractMinutesFromTime(
        nextProcessLot.processingStarted, droppedProcessLotDuration
      )
    }

    // Return the processing finished date of the preceding process lot
    return currentSortedProcessLots[$event.currentIndex - 1].data.processingFinished;
  }

  /**
   * Set the new start date of the process lot
   * @param droppedProcessLot
   * @param newStartTime
   */
  setDroppedProcessLotNewDate(droppedProcessLot: LiveReceivingProcessLot, newStartTime: Date) {
    const droppedProcessLotDuration = this.dateTimeService.getDuration(
      droppedProcessLot.processingStarted, droppedProcessLot.processingFinished
    )
    // Set dropped process lot start time to the preceding process lot end time
    droppedProcessLot.processingStarted = newStartTime;
    droppedProcessLot.processingFinished = DateTime.fromJSDate(new Date(newStartTime)).plus(droppedProcessLotDuration).toJSDate();

    // Update private droppedProcessLot
    this._droppedProcessLot = droppedProcessLot;
  }
}
