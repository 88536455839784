<form [formGroup]="doaCountForm" mat-dialog-content>
  <div class="d-bold">Edit DOA</div>
  <div>
    <span class="d-flex d-padding">
      <div>Catch Count: {{ data.flockCount | number }}</div>
    </span>
  </div>
  <div>
    <mat-radio-group aria-label="Select an option" formControlName="doaType">
      <mat-radio-button value="Count">Count</mat-radio-button>
      <mat-radio-button value="DefaultWeight">Weight</mat-radio-button>
    </mat-radio-group>
  </div>

  <span class="d-flex d-padding">
    <div class="input-group mb-3">
        <div class="input-group-prepend space-right">
          <button mat-icon-button
                  aria-label="add"
                  [disabled]="!doaCountForm.controls.doaCount.value || doaCountForm.controls.doaCount.value < 0"
                  (mousedown)="mousedown(-1)"
                  (mouseleave)="mouseup()"
                  (mouseup)="mouseup()"
                  (touchstart)="mousedown(-1); $event.preventDefault();"
                  (touchend)="mouseup()"
                  (touchcancel)="mouseup()">
            <mat-icon>remove</mat-icon>
          </button>
        </div>

        <mat-form-field>
          <mat-label>{{ doaCountForm.controls.doaType.value === 'Count' ? "DOA Count" : "DOA Weight" }} </mat-label>
          <input matInput type="number" formControlName="doaCount">
          <mat-error>{{ doaCountErrors(doaCountForm.controls.doaCount) }}</mat-error>
        </mat-form-field>

        <div class="input-group-prepend space-left">
          <button mat-icon-button
                  aria-label="subtract"
                  [disabled]="doaCountForm.controls.doaType.value === 'Count' && (doaCountForm.controls.doaCount.value ?? 0) >= data.flockCount"
                  (mousedown)="mousedown(1)"
                  (mouseup)="mouseup()"
                  (mouseleave)="mouseup()"
                  (touchstart)="mousedown(1); $event.preventDefault();"
                  (touchend)="mouseup()"
                  (touchcancel)="mouseup()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </span>
</form>

<div mat-dialog-actions align="end">
  <button mat-button color="primary"
          [mat-dialog-close]="doaCountForm.value"
          [disabled]="doaCountForm.invalid">
    Confirm
  </button>
  <button mat-button (click)="onCancelClick()">Cancel</button>
</div>
