import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownTimeComponent } from './down-time.component';



@NgModule({
  declarations: [DownTimeComponent],
  imports: [
    CommonModule
  ],
  exports:[DownTimeComponent]
})
export class DownTimeModule { }
