import { formatDate} from '@angular/common';
import { Injectable, Optional } from '@angular/core';
import {
  AppStateService,
  Cat2MqttService,
  CoreConfigurationService,
} from '@cat2/legacy-meta-cat';
import { MqttTopic } from '@cat2/legacy-meta-cat/lib/mqtt/shared/mqtt-topic';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'any' })
export class ProcessLotMqttUpdateService extends Cat2MqttService {
  protected observeRetained = true;

  private _date!: Date;
  public get date(): Date {
    return this._date;
  }
  public set date(v: Date) {
    this._date = v;
    this.changePath();
  }

  public topicReceived = new BehaviorSubject<MqttTopic>({id: ""});
  public lotId!: string;

  constructor(
    protected _core: CoreConfigurationService,
    private _state: AppStateService,
  ) {
    super(_core, '');
    _state.manufactureDate.subscribe((d) => (this.date = d));
  }

  setLot(lotId: string) {
    this.lotId = lotId;
    this.changePath();
  }

  changePath() {
    var spa = this._core.spaConfiguration.value;
    if (spa && this.date) {
      let path = `cat2/${spa.companyCode}/${spa.warehouse}/state/${formatDate(this.date,'yyy-MM-dd','en-US')}/processLots/${this.lotId? this.lotId + '/' : ''}#`;
      super.changePath(path);
    }
  }

  protected processResult = (response: MqttTopic) => {
    this.topicReceived.next(response);
  };
}
