import {Pipe, PipeTransform} from '@angular/core';
import {HeightService} from "../services/height.service";

@Pipe({
  name: 'downtimeTransform'
})
export class DowntimeTransformPipe implements PipeTransform {

  constructor(
    private heightService: HeightService
  ) {
  }

  transform(
    start: Date | undefined,
    startDateTime: Date,
    _: number
  ): number {

    // Get total pixels to be translated
    return this.heightService.getRangeHeightInPixels(startDateTime, start ?? new Date());
  }
}
