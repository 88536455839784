import {Component, OnInit, ViewChild} from '@angular/core';
import {AppStateService, Cat2AppStateModel} from "@cat2/legacy-meta-cat";
import {MatDrawer} from "@angular/material/sidenav";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'live-receiving';

  @ViewChild('flyout') public flyout: MatDrawer | undefined;

  constructor(
    private catAppState: AppStateService
  ) {
  }

  ngOnInit(): void {
    const state: Cat2AppStateModel = {
      appIcon: 'assets/icon.svg',
      appName: 'LiveReceiving'
    }

    this.catAppState.setValue(state);

    this.catAppState.setValue({
      navBarItems: [
        {
          label: 'Timeline',
          icon: 'edit',
          route: '',
          children: [],
          iconType: 'mat-icon'
        }
      ]
    });
  }
}
