import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flockStatus'
})
export class FlockStatusPipe implements PipeTransform {

  transform(value: string): string {
    switch(value.toUpperCase()){
      case 'ONLINE':
        return '#00cc66';
      case 'HANGING':
        return '#0000ff';
      case 'WAITING':
        return '#9966ff';
      case 'INTERVENTION':
        return '#ffcc00';
      case 'WARNING':
        return '#ff0000';
      case 'COMPLETED':
        return '#007f5e';
      case 'SCHEDULED':
        return '#C8C8C8';
      default:
        return '#595959';
    }
  }

}
