import {Injector, NgModule} from '@angular/core';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {ApolloClientOptions, ApolloLink, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {onError} from "@apollo/client/link/error";
import {Cat2NotificationService} from "@cat2/legacy-meta-cat";
import {environment} from "../environments/environment";


export function createApollo(httpLink: HttpLink, injector: Injector): ApolloClientOptions<any> {
  const uri = environment?.graphqlUri !== null && environment.graphqlUri !== undefined ?
    environment.graphqlUri + (!environment.graphqlUri.includes('graphql') ? '/graphql' : '') :
    environment.url + "/graphql";

  // Error handling link
  const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
      graphQLErrors.map(({message, locations, path}) => {
        const graphQLErrorDialog = injector.get(Cat2NotificationService);
        graphQLErrorDialog.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });
    }

    if (networkError) {
      const networkErrorDialog = injector.get(Cat2NotificationService);
      networkErrorDialog.error(`[Network error]: ${networkError}`);
    }
  });

  // HTTP link
  const http = httpLink.create({uri});

  // Combining the links
  const link = ApolloLink.from([errorLink, http]);

  return {
    link: link,
    cache: new InMemoryCache(),
  };
}

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Injector],
    },
  ],
})
export class GraphQLModule {
}
