import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-line-time',
  templateUrl: './line-time.component.html',
  styleUrls: ['./line-time.component.scss']
})
export class LineTimeComponent implements OnInit {
@Input() lineStartTime!:Date;
  constructor() { }

  ngOnInit(): void {
  }

}
