import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {ScrollService} from "../../../core/services/scroll.service";
import {TimeRange} from "../../../core/interfaces/time-range";
import {DatePickerService} from "../../../core/services/date-picker.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-now-button',
  templateUrl: './now-button.component.html',
  styleUrls: ['./now-button.component.scss']
})
export class NowButtonComponent implements OnInit, AfterViewInit, OnDestroy {
  currentTime: Date | undefined;
  virtualScrollViewport: CdkVirtualScrollViewport | null | undefined;
  timeRange: TimeRange[] | null = null;
  isToday = true;
  subscription?: Subscription;

  constructor(
    private scrollService: ScrollService,
    private datePickerService: DatePickerService
  ) {
  }

  ngOnInit(): void {
    this.subscription = this.datePickerService.currentScheduleDate.subscribe(date => {
      const now = new Date();
      this.isToday = date.getDate() === now.getDate() &&
        date.getMonth() === now.getMonth() &&
        date.getFullYear() === now.getFullYear();
    });
  }

  ngAfterViewInit(): void {
    this.currentTime = this.scrollService.sharedCurrentTime;
    this.scrollService.getCdkVirtualScrollViewport().subscribe(
      (viewport: CdkVirtualScrollViewport | null) => {
        this.virtualScrollViewport = viewport;
      }
    )
    this.scrollService.getSharedTimeRange().subscribe(
      (timeRange: TimeRange[] | null) => {
        this.timeRange = timeRange;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }


  scrollToCurrentTime(): void {
    setTimeout(() => {
      if (this.virtualScrollViewport && this.timeRange && this.currentTime) {
        this.scrollService.scrollToCurrentTime(
          this.currentTime,
          this.virtualScrollViewport,
          this.timeRange
        )
      }
    });
  }
}
