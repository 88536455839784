import { Pipe, PipeTransform } from '@angular/core';
import {HeightService} from "../services/height.service";
import {DateTimeService} from "../services/date-time.service";
import {Shift} from "../services/live-receiving.swagger";

@Pipe({
  name: 'shiftHeight'
})
export class ShiftHeightPipe implements PipeTransform {

  constructor(
    private heightService: HeightService,
    private dateTimeService: DateTimeService
  ) {
  }

  transform(
    shift: Shift,
    timeHeight: number
  ): number {
    return this.heightService.getRangeHeightInPixels(
     shift.startDateTime,
      shift.endDateTime
    )
  }

}
