<div id="line-bar" *ngIf="lines">
  <ng-container *ngFor="let line of lines; index as lineIndex">
    <div class="line-spacer">

    </div>
    <div class="line-content">
      <div class="number rounded-circle" *ngIf="line.line">
        <span class="fw-semibold">{{line.line}}</span>
      </div>
      <div class="line-header-container">
        <div class="name" *ngIf="line.lineName">
          {{line.lineName}}
        </div>
        <div *ngIf="getProcessLotDataForLine(line)">
          <b>Total Birds</b> {{getProcessLotDataForLine(line)!.totalBirds}},
          <b>Total Deliveries</b> {{getProcessLotDataForLine(line)!.totalDeliveries}},
          <b>Finish Date</b> {{getProcessLotDataForLine(line)!.finishDate! | date :'short'}}
        </div>
      </div>
    </div>
  </ng-container>
</div>

