<mat-drawer-container class="flyout-container">
  <mat-drawer #flyout mode="over" position="end">

    <ng-container *ngIf="processLotDetail">
      <div class="container-fluid px-0 py-4">
        <div class="lot-details row g-0 px-4 mb-2">
          <div class="col d-flex align-items-center">
            <mat-icon class="info-icon">info_outline</mat-icon>
          </div>
          <div class="col-8">
            <h1 class="mb-0">{{processLotDetail.ticketNumber}} Details</h1>
            <p class="mb-0">Process Lot {{processLotDetail.processLotId}}</p>
          </div>
          <div class="col-2 d-flex align-items-center justify-content-end">
            <button mat-icon-button aria-label="Close" (click)="closeFlyout()">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
        <div class="manufacture-dates row g-0 px-4 mb-2">
          <div class="col-6" *ngIf="processLotDetail.processingStarted">
            <mat-icon>event</mat-icon>
            <p>{{processLotDetail.processingStarted | date:'M/d/yy, h:mm a'}}</p>
          </div>
          <div class="col-6 ps-4" *ngIf="processLotDetail.processingFinished">
            <mat-icon>event_available</mat-icon>
            <p>{{processLotDetail.processingFinished | date:'M/d/yy, h:mm a'}}</p>
          </div>
        </div><!--.row-->
        <div class="border-bottom"></div>
        <div class="status row g-0">
          <div class="col-6 px-3 border-right">
            <h2>Status</h2>
            <p class="mb-1">Line {{processLotDetail.line}} - Sequence {{processLotDetail.processingSequence}}</p>
            <app-status-dot [status]="processLotDetail.status"></app-status-dot>
            <app-status-text [status]="processLotDetail.status"></app-status-text>
          </div>
          <div class="count col-6 px-3">
            <h2>Count</h2>
            <div class="row g-0">
              <div class="col-4 p-0">
                <div class="mb-1 text-icon-row">
                  <div>Catch</div>
<!--                  <legacy-meta-cat-icon [icon]="'bird'|processLotIcons" [type]="iconType" [size]="12"></legacy-meta-cat-icon>-->
                </div>
                <span>{{processLotDetail.catchCount | number}}</span>
              </div>
              <div class="col-4 p-0">
                <div class="mb-1 text-icon-row">
                  <div>Hang</div>
<!--                  <legacy-meta-cat-icon [icon]="'bird'|processLotIcons" [type]="iconType" [size]="12"></legacy-meta-cat-icon>-->
                </div>
                <span>{{processLotDetail.hangCount | number}}</span>
              </div>
              <div class="col-4 p-0">
                <div class="mb-1 text-icon-row">
                  <div>DOA</div>
<!--                  <mat-icon>dnd_forwardslash</mat-icon>-->
                </div>
                <span>{{processLotDetail.doaValue | number: "1.0-3"}} {{processLotDetail.doaType === "AverageWeight" ? " lbs" : ""}}</span>
              </div>
            </div>
          </div>
        </div><!--.status-->
        <div class="border-bottom mb-2"></div>
        <div class="source row g-0">
          <div class="col-12 px-3">
            <h2>Source</h2>
            <ul class="list-unstyled">
              <li>
                <div class="content">
                  <legacy-meta-cat-icon [icon]="'grower'|processLotIcons" [type]="iconType" [size]="14"></legacy-meta-cat-icon>
                  <strong>&nbsp;&nbsp;&nbsp;Grower:</strong> {{processLotDetail.grower?.description}}
                </div>
              </li>
              <li>
                <div class="content">
                  <legacy-meta-cat-icon [icon]="'farm'|processLotIcons" [type]="iconType" [size]="14"></legacy-meta-cat-icon>
                  <strong>&nbsp;&nbsp;&nbsp;Farm:</strong> {{processLotDetail.farm?.farmName}}
                </div>
              </li>
              <li>
                <div class="content">
                  <mat-icon>class</mat-icon>
                  <strong>Class:</strong> {{processLotDetail.classification?.classification}}
                  -{{processLotDetail.classification?.description}}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="border-bottom"></div>
        <div class="weight row g-0">
          <div class="col-6 px-3 border-right">
            <h2>Avg Live Weight</h2>
            <div class="row g-0">
              <div class="col-12 p-0 simple-data content">
                <legacy-meta-cat-icon [icon]="'weight'|processLotIcons" [type]="iconType" [size]="16"></legacy-meta-cat-icon>
                <span>&nbsp;&nbsp;&nbsp;{{processLotDetail.actualAverageWeight}}</span>
              </div>
            </div>
          </div>
          <div class="count col-6 px-3">
            <h2>Weigh Time</h2>
            <div class="row g-0">
              <div class="col-12 p-0 simple-data content">
                <mat-icon>access_time</mat-icon>
                <span>{{processLotDetail.weighTime | date:'M/d/yy, h:mm a'}}</span>
              </div>
            </div>
          </div>
        </div><!--.weight-->
        <div class="border-bottom"></div>
        <div class="truck row g-0">
          <div class="col-6 px-3 border-right">
            <h2>Trailer ID</h2>
            <div class="row g-0">
              <div class="col-12 p-0 simple-data content">
                <legacy-meta-cat-icon [icon]="'truck'|processLotIcons" [type]="iconType" [size]="16"></legacy-meta-cat-icon>
                <span>{{processLotDetail.trailerID}}</span>
              </div>
            </div>
          </div>
          <div class="count col-6 px-3">
            <h2>Ticket Status</h2>
            <div class="row g-0">
              <div class="col-12 p-0 simple-data">
                <span>{{processLotDetail.status}}</span>
              </div>
            </div>
          </div>
        </div><!--.truck-->
        <div class="border-bottom"></div>
        <div class="lot-modules row g-0">
          <div class="col-6 px-3 border-right">
            <h2>Grower Lot ID</h2>
            <div class="row g-0">
              <div class="col-12 p-0 simple-data">
                <mat-icon>location_pin</mat-icon>
                <span>{{processLotDetail.growerLot}}</span>
              </div>
            </div>
          </div>
          <div class="count col-6 px-3">
          </div>
        </div><!--.lot-modules-->
        <div class="border-bottom"></div>
        <div class="buttons row g-0 mt-3">
          <div class="col-6 px-3">
            <button mat-flat-button color="primary" (click)="openEditCountDOA()">Edit DOA Count</button>
          </div>
          <div class="col-6 px-3" *ngIf="isHanging">
            <button autofocus mat-flat-button color="primary" (click)="splitLot(processLotDetail)">Split Lot</button>
          </div>
          <div class="col-6 px-3">
            <button mat-flat-button color="primary" (click)="selectLastLot(processLotDetail)">Last Process Lot</button>
          </div>
        </div><!--.buttons-->
      </div><!--.container-->
    </ng-container>
  </mat-drawer>
</mat-drawer-container>
