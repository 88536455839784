import { Pipe, PipeTransform } from '@angular/core';
import {HeightService} from "../services/height.service";

@Pipe({
  name: 'currentTimeTransform'
})
export class CurrentTimeTransformPipe implements PipeTransform {

  constructor(
    private heightService: HeightService
  ) {
  }

  transform(
    currentTime: Date,
    startDateTime:Date
  ): string {
    // Get total pixels to be translated
    let pixels = this.heightService.getRangeHeightInPixels(
      startDateTime,
      currentTime
    )

    return `translateY(${pixels}px)`
  }

}
