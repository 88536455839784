<mat-dialog-content>
  <h2 class="fw-bold">Set Official Count</h2>
  <form [formGroup]="officialCountForm" class="class-form">
    <mat-form-field appearance="outline" class="dp-width">
      <mat-label>Official Source</mat-label>
      <mat-select formControlName="source" (selectionChange)="setOfficialCount()">
        <mat-option value="HANG">Hang</mat-option>
        <mat-option value="CATCH">Catch</mat-option>
        <mat-option value="MANUAL">Manual</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Official Count</mat-label>
      <input matInput formControlName="count" type="number" [readonly]="disableInput">
    </mat-form-field>

  </form>
  <div class="class-form-actions">
    <button mat-raised-button color="primary" [disabled]="!officialCountForm.valid" (click)="submitForm()">Confirm</button>
    <button mat-raised-button color="accent" (click)="onCancelClick()">Cancel</button>
  </div>
</mat-dialog-content>
