import {Component, Input, OnInit} from '@angular/core';
import {LiveReceivingProcessLot} from "../../core/services/live-receiving.swagger";

@Component({
  selector: 'app-percentage-bar',
  templateUrl: './percentage-bar.component.html',
  styleUrls: ['./percentage-bar.component.scss']
})
export class PercentageBarComponent implements OnInit {
  @Input () processLot!:LiveReceivingProcessLot;
  constructor() { }

  ngOnInit(): void {
  }

}
