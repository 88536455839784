import {Injectable} from '@angular/core';
import {TimeRange} from "../interfaces/time-range";
import {DateTime} from "luxon";
import {BehaviorSubject} from "rxjs";
import {Downtime, Lines, LiveReceivingProcessLot} from "./live-receiving.swagger";
import * as lodash from "lodash";

@Injectable({
  providedIn: 'root'
})
export class TimelineService {
  get minutesSplit$(): BehaviorSubject<number> {
    return this._minutesSplit$;
  }

  // Sets how an hour should be divided
  // IE - 30 minutes splits the hour into two segments
  private _minutesSplit$ = new BehaviorSubject<number>(60);
  timeHeight = 500;
  minDurationSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  constructor() { }

  /**
   * Create time range from start date and end date
   * @param startDateParam
   * @param endDateParam
   */
  createTimeRange(startDateParam: Date, endDateParam: Date): TimeRange[] {
    let start = DateTime.fromJSDate(startDateParam);
    const stop = DateTime.fromJSDate(endDateParam);
    const result: TimeRange[] = [];
    for(
      start;
      start.valueOf() <= stop.valueOf();
      start = start.plus({ minutes: this._minutesSplit$.value })
    ) {
      const startDate = start.toJSDate();
      const endDate = start.plus( {minutes: this._minutesSplit$.value} ).toJSDate();
      result.push({startDate, endDate});
    }
    return result;
  }

  /**
   * Filters the correct ProcessLot to the correct Line
   * @param line
   * @param processLots
   */
  filterProcessLotToLine(line: Lines, processLots: LiveReceivingProcessLot[]): LiveReceivingProcessLot[] | undefined {
    if(processLots) {
      let lots = processLots.filter(
        (processLot: LiveReceivingProcessLot) => processLot.line === line.line
      );
      return this.sortProcessLotsByDate(lots);
    }
    return undefined;
  }

  /**
   * Filter the correct Downtime to the correct Line
   * @param line
   * @param downtimeEvents
   */
  filterDownTimeToLine(line: Lines, downtimeEvents: Downtime[]): Downtime[] | undefined{
    if (downtimeEvents) {
      return downtimeEvents.filter(
        (downtime: Downtime) => downtime.line === line.line
      );
    }
    return undefined;

  }

  /**
   * Sort process lots by date
   * @param processLots
   */
  sortProcessLotsByDate(processLots: LiveReceivingProcessLot[]) {
    return lodash.sortBy(processLots, ["processingStarted"]);
  }


}
