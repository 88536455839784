import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  API_OVERRIDE,
  BYPASS_CAT2_SPA_CONFIGURATION,
  Cat2AppShellModule,
  Cat2FormModule,
  MOCK_API,
  Cat2OAuthConfig
} from "@cat2/legacy-meta-cat";
import {MaterialModule} from "./shared/material/material.module";
import {environment} from "../environments/environment";
import {CoreModule} from "./core/core.module";
import {OAuthModule} from "angular-oauth2-oidc";
import {API_BASE_URL, DowntimeClient, LinesClient} from "./core/services/live-receiving.swagger";
import {PagesModule} from "./pages/pages.module";
import {FlyoutModule} from "./features/flyout/flyout.module";
import {MatSidenavModule} from "@angular/material/sidenav";
import {FlyoutService} from "./core/services/flyout.service";
import { GraphQLModule } from './graphql.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    OAuthModule.forRoot(Cat2OAuthConfig),
    Cat2AppShellModule,
    Cat2FormModule,
    MaterialModule,
    PagesModule,
    FlyoutModule,
    MatSidenavModule,
    GraphQLModule
  ],
  providers: [
    DowntimeClient,
    LinesClient,
    FlyoutService,
    {provide: API_BASE_URL, useValue: environment.url},
    {provide: API_OVERRIDE, useValue: environment.url},
    {provide: MOCK_API, useValue: environment.mockApi},
    {provide: BYPASS_CAT2_SPA_CONFIGURATION, useValue: environment.bypassConfig}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
