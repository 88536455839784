<mat-card appearance="outlined" class="flock-card mat-elevation-z0" *ngIf="processLot" [style.background]="isFiltered?'lightgray':''">
  <div class="div-1">
    <app-flock-card-overlay [processLot]="processLot"></app-flock-card-overlay>
    <app-status-bar [statusText]="processLot.status"></app-status-bar>
    <div class="d-flex flex-column ml-10">
      <div class="d-flex align-items-center">
        <div class="flock-padding app-status-dot d-inline-flex">
          <app-status-dot [status]="processLot.status"></app-status-dot>
        </div>
        <div class="flock-padding app-flock-id d-inline-flex">
          <app-flock-id [flockCardId]="processLot.processLotId"></app-flock-id>
        </div>
        <div class="flock-padding app-status-text d-inline-flex">
          <app-status-text [status]="processLot.status!"></app-status-text>
        </div>
        <div class="flock-padding app-line-time d-flex">
          <app-line-time [lineStartTime]="processLot.processingStarted!"></app-line-time>
          <div class="dash">&nbsp;-&nbsp;</div>
          <app-line-time [lineStartTime]="processLot.processingFinished!"></app-line-time>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="app-flock-chip-list">
          <app-chip-list [processLot]="processLot"></app-chip-list>
        </div>
      </div>
    </div>
  </div>
  <app-drag *ngIf="!onPreview" [hidden]="!canDrag"></app-drag>
</mat-card>
