import {Component, OnInit} from '@angular/core';
import {Lines, LinesClient, PayloadOfListOfLines, LiveReceivingProcessLot} from "../../core/services/live-receiving.swagger";
import {AppStateService, Cat2NotificationService} from "@cat2/legacy-meta-cat";
import {compareLines} from "../../core/helpers/compare-lines";
import { TimelineService } from '../../core/services/timeline.service';
import { ProcessLotService } from '../../core/services/process-lot.service';

interface LineProcessLotInfo {
  totalBirds: number;
  totalDeliveries: number;
  finishDate: Date;
}

@Component({
  selector: 'app-line-bar',
  templateUrl: './line-bar.component.html',
  styleUrls: ['./line-bar.component.scss']
})
export class LineBarComponent implements OnInit {
  lines!: Lines[];
  processLots!: LiveReceivingProcessLot[];

  constructor(
    private linesClient: LinesClient,
    private cat2NotificationService: Cat2NotificationService,
    private appStateService: AppStateService,
    public timelineService: TimelineService,
    public processLotService: ProcessLotService,
  ) {
  }

  ngOnInit(): void {
    this.getLines();
  }

  /**
   * Get Lines
   */
  getLines(): void {
    this.appStateService.showLoadingOverlay();
    this.linesClient.getKillLines().subscribe(
      (response: PayloadOfListOfLines) => {
        this.lines = response.data.sort((a, b) => compareLines(a, b));
        this.appStateService.hideLoadingOverlay();
        this.processLotService.processLotSubject$.subscribe(processLots => {
          this.processLots = processLots;
        });
      },
      error => {
        return this.cat2NotificationService.error(error);
      }
    );
  }

  /**
   * gets the process lots for the line
   */
  getProcessLotDataForLine(line: Lines): LineProcessLotInfo | undefined {
    const lineProcessLots = this.timelineService.filterProcessLotToLine(
      line,
      this.processLots
    );
    if (lineProcessLots?.length) {
      const totalBirds = lineProcessLots.reduce(
        (total: number, processLot: LiveReceivingProcessLot) => {
          return total + processLot.catchCount;
        },
        0
      );
      const totalDeliveries = lineProcessLots.length;
      const finishDate = lineProcessLots.reduce((latestDate: Date | null, currentItem) => {
        const currentDate = currentItem.processingFinished;
        if (!latestDate || currentDate > latestDate) {
          return currentDate;
      }
      return latestDate;
      }, null) as Date;


      return {
        totalBirds,
        totalDeliveries,
        finishDate
      };
    } else {
      return undefined;
    }

  }


}
