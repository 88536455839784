import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {GetSettingOfficialCountSourceGQL} from "../../../graphql/graphql";

export interface DialogData {
  officialCount: number;
  catchCount: number;
  hangCount: number;
  officialCountSource: 'HANG' | 'CATCH' | 'MANUAL';
}

@Component({
  selector: 'app-select-official-count',
  templateUrl: './select-official-count.component.html',
  styleUrls: ['./select-official-count.component.scss']
})
export class SelectOfficialCountComponent implements OnInit {

  disableInput: boolean = true;
  officialCountForm = this.fb.group({
    source: new FormControl<'HANG' | 'CATCH' | 'MANUAL' | null>(null, [Validators.required]),
    count: new FormControl<number | null>(null, [Validators.required, Validators.min(0)])
  })

  constructor(
    private fb: FormBuilder,
    private getSettingOfficialCountSourceGQL: GetSettingOfficialCountSourceGQL,
    public dialogRef: MatDialogRef<SelectOfficialCountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  ngOnInit(): void {
    if (this.data.officialCountSource) {
      this.officialCountForm.patchValue({source: this.data.officialCountSource});
      this.setOfficialCount();
      return;
    }
    this.getSettingOfficialCountSourceGQL.watch(undefined, {fetchPolicy: "no-cache"}).valueChanges
      .subscribe(d => {
        this.officialCountForm.patchValue({source: d.data.settingOfficialCountSource});
        this.setOfficialCount();
      });
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  submitForm() {
    this.dialogRef.close(this.officialCountForm.value);
  }


  setOfficialCount() {
    switch (this.officialCountForm.get('source')?.value) {
      case 'HANG':
        this.officialCountForm.get('count')?.setValue(this.data.hangCount);
        this.disableInput = true;
        break;
      case 'CATCH':
        this.officialCountForm.get('count')?.setValue(this.data.catchCount);
        this.disableInput = true;
        break;
      case 'MANUAL':
        this.officialCountForm.get('count')?.setValue(this.data.officialCount ?? 0);
        this.disableInput = false;
        break;
    }
  }
}
