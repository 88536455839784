<mat-chip-listbox class="chip-list" aria-label="Flock Information" *ngIf="processLot">

  <mat-chip-option disableRipple="true" matTooltip="Grower">
      <div class="content">
        <legacy-meta-cat-icon class="me-2" [icon]="'grower'|processLotIcons" [type]="iconType" [size]="18"></legacy-meta-cat-icon>
        {{processLot.grower?.description}}
      </div>
  </mat-chip-option>

  <mat-chip-option disableRipple="true" matTooltip="Farm">
    <div class="content">
      <legacy-meta-cat-icon class="me-2" [icon]="'farm'|processLotIcons" [type]="iconType" [size]="18"></legacy-meta-cat-icon>
      {{processLot.farm?.farmName}}
    </div>
  </mat-chip-option>

  <mat-chip-option disableRipple="true" matTooltip="Classification">
    <div class="content">
      <mat-icon>class</mat-icon>
      {{processLot.classification?.classification}}-{{processLot.classification?.description}}
    </div>
  </mat-chip-option>

  <mat-chip-option disableRipple="true" matTooltip="Hang Count/Catch Count">
    <div class="content">
      <legacy-meta-cat-icon class="me-2" [icon]="'bird'|processLotIcons" [type]="iconType" [size]="18"></legacy-meta-cat-icon>
      {{processLot.hangCount | number}}/{{processLot.catchCount | number}}
    </div>
  </mat-chip-option>
</mat-chip-listbox>
