import {Component, Input, OnInit} from '@angular/core';
import {MergeService} from "../../../core/services/merge.service";
import {LiveReceivingProcessLot} from "../../../core/services/live-receiving.swagger";
import {prompt_canOnlySelectWaitingLot} from "../../../shared/strings";
import {Cat2NotificationService} from "@cat2/legacy-meta-cat";

@Component({
  selector: 'app-flock-card-overlay',
  templateUrl: './flock-card-overlay.component.html',
  styleUrls: ['./flock-card-overlay.component.scss']
})
export class FlockCardOverlayComponent implements OnInit {
  @Input() processLot?: LiveReceivingProcessLot;
  isJoining = false;
  selected = false;

  get isWaiting(): boolean {
    return this.processLot?.status === 'WAITING';
  }

  constructor(
    private mergeService: MergeService,
    private cat2NotificationService: Cat2NotificationService,
  ) {
  }

  ngOnInit(): void {
    this.mergeService.mergeSubject$.subscribe((value) => this.isJoining = value);
    this.mergeService.selectedLotsSubject$.subscribe(value =>
      this.selected = value.some(l => l.processLotId == this.processLot?.processLotId)
    );
  }

  onClick() {
    if (this.processLot && this.processLot.status === 'WAITING') {
      if (this.selected) {
        this.deselectLot(this.processLot);
      } else {
        this.selectLot(this.processLot);
      }
    } else {
      this.cat2NotificationService.error(prompt_canOnlySelectWaitingLot);
    }
  }

  selectLot(processLot: LiveReceivingProcessLot) {
    this.mergeService.activeLot = processLot;
    this.mergeService.selectLotForJoin(processLot);
  }

  deselectLot(item: LiveReceivingProcessLot) {
    this.mergeService.activeLot = undefined;
    this.mergeService.deselectLotForJoin(item);
  }
}
