import {Pipe, PipeTransform} from '@angular/core';
import {LiveReceivingProcessLot} from "../services/live-receiving.swagger";
import {HeightService} from "../services/height.service";
import {DateTimeService} from "../services/date-time.service";

@Pipe({
  name: 'processLotHeight',
})
export class ProcessLotHeightPipe implements PipeTransform {

  constructor(
    private heightService: HeightService,
    private dateTimeService: DateTimeService
  ) {
  }

  transform(
    processLot: LiveReceivingProcessLot,
    timeHeight: number
  ): number {

    return this.heightService.getRangeHeightInPixels(
      processLot.processingStarted,
      processLot.processingFinished
    )
  }

}
