import {Component, Input, OnInit} from '@angular/core';
import {TimeRange} from "../../../core/interfaces/time-range";
import {TimeDisplay} from "../../../core/interfaces/time-display";

@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})
export class TimeComponent implements OnInit {
  @Input() timeInterval!: TimeRange;
  @Input() timeHeight!: number;
  @Input() timeDisplay!: TimeDisplay;

  constructor() {

  }

  ngOnInit(): void {
  }



}
