import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FlyoutService} from "../../core/services/flyout.service";
import {MatDrawer, MatDrawerToggleResult} from "@angular/material/sidenav";
import {Observable, throwError} from "rxjs";
import {
  CountType,
  LiveReceivingProcessLot,
  PayloadOfBoolean,
  PayloadOfLiveReceivingProcessLot,
  ProcessLotsClient,
  SelectLastLotRequest,
  SplitProcessLotRequest, UpdateCountType,
  UpdateProcessLotDoaCountsRequest
} from "../../core/services/live-receiving.swagger";
import {AppStateService, Cat2DialogService, Cat2NotificationService} from "@cat2/legacy-meta-cat";
import {DialogData, EditCountDoaComponent} from "./edit-count-doa/edit-count-doa.component";
import {MatDialog} from "@angular/material/dialog";
import {prompt_confirmLastProcessLot, prompt_confirmSplit} from "../../shared/strings";
import {DatePickerService} from "../../core/services/date-picker.service";
import {DateTimeService} from "../../core/services/date-time.service";
import {Router} from "@angular/router";
import {IconFamilies} from "@cat2/legacy-meta-cat/lib/shared/metadata/icon-families";

@Component({
  selector: 'app-flyout',
  templateUrl: './flyout.component.html',
  styleUrls: ['./flyout.component.scss']
})
export class FlyoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('flyout', {static: false}) flyout!: MatDrawer;
  processLotDetail!: LiveReceivingProcessLot;
  processLotsObservable: Observable<PayloadOfLiveReceivingProcessLot> | undefined;
  iconType: IconFamilies = "asset";

  get isHanging(): boolean {
    return this.processLotDetail?.status === 'HANGING';
  }

  constructor(
    public dialog: MatDialog,
    private flyoutService: FlyoutService,
    private _processLotsClient: ProcessLotsClient,
    private cat2NotificationService: Cat2NotificationService,
    private appStateService: AppStateService,
    private _dialog: Cat2DialogService,
    private _notification: Cat2NotificationService,
    private schedulerStateService: DatePickerService,
    private dateService: DateTimeService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    this.flyoutService.flyoutToggleSubject$.subscribe(
      (value) => {
        if (value) {
          this.flyout.open().then(
            (error: MatDrawerToggleResult) => throwError(error)
          );
          this.getProcessLotDetails();
        } else {
          this.flyout.close().then(
            (error: MatDrawerToggleResult) => throwError(error)
          );
        }
      }
    );
  }

  ngOnDestroy() {
    this.flyoutService.flyoutToggleSubject$.complete();
  }

  getProcessLotDetails() {
    this.appStateService.showLoadingOverlay();
    this.processLotsObservable = this._processLotsClient.getLotById(this.flyoutService.processLotId);
    if (this.processLotsObservable) {
      this.processLotsObservable.subscribe({
        next: (payloadOfProcessLot: PayloadOfLiveReceivingProcessLot) => {
          if (payloadOfProcessLot.data != undefined) {
            this.processLotDetail = payloadOfProcessLot.data;
          }
          this.appStateService.hideLoadingOverlay();
        },
        error: (error) => this.cat2NotificationService.error(error)
      })
    }
  }

  openEditCountDOA(): void {
    const dialogRef = this.dialog.open(EditCountDoaComponent, {
      data: {flockCount: this.processLotDetail.catchCount, doaCount: this.processLotDetail.doaValue, doaType: this.processLotDetail.doaType},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateDOACount(result);
      }
    });
  }

  updateDOACount(result: DialogData): void {
    let updateData = UpdateProcessLotDoaCountsRequest.fromJS({
      processlotguid: this.processLotDetail.guid,
      line: this.processLotDetail.line,
      doabirdcount: result.doaCount,
      doaType: result.doaType==CountType.Count?UpdateCountType.Count:UpdateCountType.DefaultWeight
    })
    this._processLotsClient.updateDoaCount(updateData).subscribe(
      (response: PayloadOfBoolean) => {
        if (response) {
          this.processLotDetail.doaValue = result.doaCount;
        }
      },
      error => throwError(error)
    );
  }

  //#region Split
  splitLot(item: LiveReceivingProcessLot) {
    this._dialog.ShowYesNoDialog('',
      prompt_confirmSplit(item.processLotId!),
      (yes) => {
        if (yes) {
          this._processLotsClient.splitLot(SplitProcessLotRequest.fromJS({
            processLotGuid: this.processLotDetail.guid,
            quantity: 0,
          })).subscribe(
            () => {
              this.closeFlyout();
              const currentDate = this.dateService.transformDate(this.schedulerStateService.currentScheduleDate.value);
              const params = [
                'schedule',
                currentDate
              ]
              this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                this.router.navigate(params, {replaceUrl: true}).then(() => {
                });
              });
            },
            (error) => this._notification.error(error)
          );
        }
      }
    );
  }

  //#endregion
  //#region selectLastLot
  selectLastLot(item: LiveReceivingProcessLot) {
    this._dialog.ShowYesNoDialog('',
      prompt_confirmLastProcessLot(item.processLotId!),
      (yes) => {
        if (yes) {
          this._processLotsClient.selectLastLot(SelectLastLotRequest.fromJS({
            processLotGuid: this.processLotDetail.guid
          })).subscribe(
            () => {
              this.closeFlyout();
              const currentDate = this.dateService.transformDate(this.schedulerStateService.currentScheduleDate.value);
              const params = [
                'schedule',
                currentDate
              ]
              this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                this.router.navigate(params, {replaceUrl: true}).then(() => {
                });
              });
            },
            (error) => this._notification.error(error)
          );
        }
      }
    );
  }

  //#endregion

  /**
   * Close flyout
   */
  closeFlyout(): void {
    this.flyout.close().then(
      (error: MatDrawerToggleResult) => throwError(error)
    )
  }

}
