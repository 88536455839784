import {Component, Input} from '@angular/core';

@Component({
  selector: 'time-table-toolbar',
  templateUrl: './time-table-toolbar.component.html',
  styleUrls: ['./time-table-toolbar.component.scss']
})
export class TimeTableToolbarComponent {
  @Input() isHaulingPath: boolean = false;
}
