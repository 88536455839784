import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TicketsService} from "../../../core/services/tickets.service";
import {FormControl} from "@angular/forms";

interface Ticket {
  __typename?: 'Ticket',
  ticketNumber?: string | null,
  guid: string
}

@Component({
  selector: 'app-attribute-dialog',
  templateUrl: './attribute-dialog.component.html',
  styleUrls: ['./attribute-dialog.component.scss']
})
export class AttributeDialogComponent implements OnInit {
  tickets?: Ticket[];
  formField = new FormControl<Ticket | null>(null);

  constructor(
    private ticketsService: TicketsService,
    private dialogRef: MatDialogRef<AttributeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: string,
  ) {
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onConfirmClick() {
    if (this.formField.value) {
      this.dialogRef.close(this.formField.value?.guid);
    }
  }

  ngOnInit(): void {
    this.ticketsService.getTicketsForManufactureDate(new Date(this.data)).subscribe(result => {
      this.tickets = result.data.ticketsForManufactureDate;
    })
  }
}
