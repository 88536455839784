import { Component, OnInit } from '@angular/core';
import {
  JoinProcessLotsRequest,
  LiveReceivingProcessLot,
  ProcessLotsClient
} from "../../../core/services/live-receiving.swagger";
import {AppStateService, Cat2DialogService, Cat2NotificationService} from "@cat2/legacy-meta-cat";
import {prompt_confirmCancelJoin, prompt_confirmJoin} from "../../../shared/strings";
import {MergeService} from "../../../core/services/merge.service";
import {ProcessLotService} from "../../../core/services/process-lot.service";
import {DatePickerService} from "../../../core/services/date-picker.service";
import {DateTimeService} from "../../../core/services/date-time.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-merge',
  templateUrl: './merge.component.html',
  styleUrls: ['./merge.component.scss']
})
export class MergeComponent implements OnInit {
  isJoining: boolean | undefined;
  selectedLots: LiveReceivingProcessLot[] | undefined;
  constructor(
    private _notification: Cat2NotificationService,
    private mergeService: MergeService,
    private _dialog: Cat2DialogService,
    private _lotClient:ProcessLotsClient,
    private processLotService: ProcessLotService,
    private schedulerStateService: DatePickerService,
    private dateService: DateTimeService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }
  ngAfterViewInit():void{
    this.mergeService.selectedLotsSubject$.subscribe(
      (value) => {
          this.selectedLots=value;
      }
    );
  }
  startJoin() {
    this.mergeService.startJoin();
    this.mergeService.mergeToggle(true);
    this.isJoining=true;
  }

  cancelJoin(): void {
    this._dialog.ShowYesNoDialog('',prompt_confirmCancelJoin, (yes) => {
      if (yes) this.stopJoining();
    });
  }

  stopJoining() {
    this.mergeService.deselectAllLots();
    this.mergeService.mergeToggle(false);
    this.isJoining = false;
    this._notification.dismiss();
  }
  joinLots(){
    this._dialog.ShowYesNoDialog('',prompt_confirmJoin, (yes) => {
      if (yes) {
        //the first selected item is always the base
        const baseProcessLotGuid = this.mergeService.selectedLots![0].guid;
        //the rest of the selected items are joined to the base
        const joinedProcessLotGuids = this.mergeService.selectedLots!
          .filter((lot, i) => i > 0)
          .map((lot) => lot.guid);

        //make the call to join the selected lots
        this._lotClient.joinLots(JoinProcessLotsRequest.fromJS({
          baseProcessLotGuid,
          joinedProcessLotGuids,
        })).subscribe(
          (newLots) => {
            this.stopJoining();
            const currentDate = this.dateService.transformDate(this.schedulerStateService.currentScheduleDate.value);
            const params = [
              'schedule',
              currentDate
            ]
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
              this.router.navigate(params, {replaceUrl: true}).then(r => {});
            });
          },
          (error) => this._notification.error(error)
        );
      }
    });
  }
}
