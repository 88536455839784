import { Pipe, PipeTransform } from '@angular/core';
import {TimeRange} from "../interfaces/time-range";
import {TimeDisplay} from "../interfaces/time-display";

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {
  hourDisplayRule: TimeDisplay[] = [
    {
      timeValue: 0,
      timeFormat: 'h a',
    },
  ];

  transform(interval: TimeRange): TimeDisplay {
    return this.hourDisplayRule[0];
  }


}
