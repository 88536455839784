import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-flock-id',
  templateUrl: './flock-id.component.html',
  styleUrls: ['./flock-id.component.scss']
})
export class FlockIdComponent implements OnInit {
@Input() flockCardId: string | undefined;
  constructor() { }

  ngOnInit(): void {
  }

}
