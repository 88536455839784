import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HaulingQueueGridComponent} from './hauling-queue-grid.component';
import {Cat2DataGridModule} from "@cat2/legacy-meta-cat";
import { TopToolbarModule } from '../top-toolbar/top-toolbar.module';
import { GridMenuComponent } from './grid-menu/grid-menu.component';
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatTooltipModule} from "@angular/material/tooltip";
import {SelectOfficialCountComponent} from "./select-official-count/select-official-count.component";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { ChangeManufactureDateComponent } from './change-manufacture-date/change-manufacture-date.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import { AttributeDialogComponent } from './attribute-dialog/attribute-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";


@NgModule({
  declarations: [
    HaulingQueueGridComponent,
    GridMenuComponent,
    SelectOfficialCountComponent,
    ChangeManufactureDateComponent,
    AttributeDialogComponent
  ],
  exports: [
    HaulingQueueGridComponent
  ],
    imports: [
        CommonModule,
        Cat2DataGridModule,
        TopToolbarModule,
        MatButtonModule,
        MatMenuModule,
        MatTooltipModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatDialogModule,
        FormsModule,
        MatPaginator,
    ]
})
export class HaulingQueueGridModule {
}
