<div *ngIf="isShow" id="app-filter-bar" class="d-flex justify-content-end align-items-center">
  <div class="filters">
    <button mat-button [matMenuTriggerFor]="growerMenu"><span [style.font-weight]="isGrowerReferenceFiltered?'bold':''" >{{growerReferenceFilterTitle}}</span><mat-icon aria-hidden="true">expand_more</mat-icon></button>
    <mat-menu #growerMenu="matMenu">
      <div class="filter-items" *ngFor="let growerReference of filtersList.growerReferences">
        <mat-checkbox #growerReference class="mat-menu-checkbox" (change)="filterByGrowerReference($event,growerReference)">
          <div class="checkbox">{{growerReference}}</div>
        </mat-checkbox>
      </div>
      <div class="clear-filter-container">
        <button mat-button class="clear-filter" [disableRipple]="true" (click)="clearFilter('growerReference')"><mat-icon>close</mat-icon><span>Clear</span></button>
      </div>
    </mat-menu>
  </div>
  <div class="filters">
    <button mat-button [matMenuTriggerFor]="processLotMenu"><span [style.font-weight]="isProcessLotFiltered?'bold':''">{{processLotFilterTitle}}</span><mat-icon aria-hidden="true">expand_more</mat-icon></button>
    <mat-menu #processLotMenu="matMenu">
      <div class="filter-items" *ngFor="let processLotId of filtersList.processLotIds">
        <mat-checkbox #processLot class="mat-menu-checkbox" (change)="filterByProcessLotId($event,processLotId)">
          <div class="checkbox">{{processLotId}}</div>
        </mat-checkbox>
      </div>
      <div class="clear-filter-container">
        <button mat-button class="clear-filter" [disableRipple]="true" (click)="clearFilter('processLot')"><mat-icon>close</mat-icon><span>Clear</span></button>
      </div>
    </mat-menu>
  </div>
  <div class="filters">
    <button mat-button [matMenuTriggerFor]="growerLotMenu"><span [style.font-weight]="isGrowerLotFiltered?'bold':''">{{growerLotFilterTitle}}</span><mat-icon aria-hidden="true">expand_more</mat-icon></button>
    <mat-menu #growerLotMenu="matMenu">
      <div class="filter-items" *ngFor="let growerLotId of filtersList.growerLotIds">
        <mat-checkbox #growerLot class="mat-menu-checkbox" (change)="filterByGrowerLotId($event,growerLotId)">
          <div class="checkbox">{{growerLotId}}</div>
        </mat-checkbox>
      </div>
      <div class="clear-filter-container">
        <button mat-button class="clear-filter" [disableRipple]="true" (click)="clearFilter('growerLot')"><mat-icon>close</mat-icon><span>Clear</span></button>
      </div>
    </mat-menu>
  </div>
  <div class="filters">
    <button mat-button [matMenuTriggerFor]="classMenu"><span [style.font-weight]="isClassificationFiltered?'bold':''">{{classificationFilterTitle}}</span><mat-icon aria-hidden="true">expand_more</mat-icon></button>
    <mat-menu #classMenu="matMenu">
      <div class="filter-items" *ngFor="let classification of filtersList.classifications">
        <mat-checkbox #classification class="mat-menu-checkbox" (change)="filterByClassification($event,classification)">
          <div class="checkbox">{{classification}}</div>
        </mat-checkbox>
      </div>
      <div class="clear-filter-container">
        <button mat-button class="clear-filter" [disableRipple]="true" (click)="clearFilter('classification')"><mat-icon>close</mat-icon><span>Clear</span></button>
      </div>
    </mat-menu>
  </div>
  <div class="filter-bar-close">
    <button mat-icon-button aria-label="Close Filter Bar" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
