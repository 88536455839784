import {Component, Input, OnInit} from '@angular/core';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-down-time',
  templateUrl: './down-time.component.html',
  styleUrls: ['./down-time.component.scss'],
  providers: [DatePipe]
})
export class DownTimeComponent implements OnInit {
  @Input() start?: Date;
  @Input() end?: Date;
  @Input() isExclusion = false;

  downtimeStart: string | null | undefined;
  downtimeEnd: string | null | undefined;

  constructor(
    private datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    if (this.start && this.end) {
      this.downtimeStart = this.datePipe.transform(this.start, 'shortTime');
      this.downtimeEnd = this.datePipe.transform(this.end, 'shortTime');
    }
  }
}
