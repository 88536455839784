import { Pipe, PipeTransform } from '@angular/core';
import {HeightService} from "../services/height.service";

@Pipe({
  name: 'buffer'
})
export class BufferPipe implements PipeTransform {
  constructor(
    private heightService: HeightService
  ) {
  }
  transform(bufferStartTime: Date, bufferEndTime:Date,timeHeight: number): number {
    return this.heightService.getRangeHeightInPixels(
      bufferStartTime,
      bufferEndTime
    );
  }

}
