import {Component, Input, OnInit} from '@angular/core';
import {LiveReceivingProcessLot} from "../../../core/services/live-receiving.swagger";
import {IconFamilies} from "@cat2/legacy-meta-cat/lib/shared/metadata/icon-families";

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss']
})
export class ChipListComponent implements OnInit {
  @Input() processLot!: LiveReceivingProcessLot;
  iconType: IconFamilies = "asset";
  constructor() { }
  ngOnInit(): void {
  }

}
