import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DayTimelineComponent } from './day-timeline.component';
import {ScrollingModule} from "@angular/cdk/scrolling";
import { DownTimeModule } from '../down-time/down-time.module';
import {MatButtonModule} from "@angular/material/button";
import {FlockCardModule} from "../flock-card/flock-card.module";
import {TimeModule} from "./time/time.module";
import {CoreModule} from "../../core/core.module";
import {CurrentTimeMarkerModule} from "../current-time-marker/current-time-marker.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {ShiftModule} from "../shift/shift.module";
import {ShiftHeightPipe} from "../../core/pipes/shift-height.pipe";
import {ShiftTransformPipe} from "../../core/pipes/shift-transform.pipe";



@NgModule({
  declarations: [
    DayTimelineComponent,
    ShiftHeightPipe,
    ShiftTransformPipe
  ],
    exports: [
        DayTimelineComponent
    ],
  imports: [
    CommonModule,
    ScrollingModule,
    DownTimeModule,
    MatButtonModule,
    FlockCardModule,
    TimeModule,
    CoreModule,
    CurrentTimeMarkerModule,
    DragDropModule,
    ShiftModule
  ]

})
export class DayTimelineModule { }
