import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {PayloadOfListOfLiveReceivingProcessLot, PayloadOfLiveReceivingProcessLot, LiveReceivingProcessLot, ProcessLotsClient} from "./live-receiving.swagger";

@Injectable({
  providedIn: 'root'
})
export class FlyoutService {
  set flyoutToggleSubject$(value: BehaviorSubject<boolean>) {
    this._flyoutToggleSubject$ = value;
  }
  get flyoutToggleSubject$(): BehaviorSubject<boolean> {
    return this._flyoutToggleSubject$;
  }
  private _flyoutToggleSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  processLotId:string='';

  constructor() {}

  /**
   * Emit open value of true
   */
  open() {
    return this._flyoutToggleSubject$.next(true)
  }
  /**
   * Emit close value of false
   */
  close() {
    return this._flyoutToggleSubject$.next(false);
  }

  /**
   * Toggle close or open value of true or false
   */
  toggle() {
    if (this._flyoutToggleSubject$.value) {
      return this._flyoutToggleSubject$.next(false)
    }
    return this._flyoutToggleSubject$.next(true);
  }
}
