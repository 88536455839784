<div id="schedule-container">
  <div class="schedule-container-top">
    <div class="time-table-toolbar">
      <time-table-toolbar></time-table-toolbar>
      <app-line-bar></app-line-bar>
    </div>
    <div class="filter-bar">
      <app-filter-bar></app-filter-bar>
    </div>
  </div><!--.schedule-container-top-->
  <div class="schedule-container-bottom">

    <app-day-timeline [urlDate]="date"></app-day-timeline>
  </div><!--.schedule-container-bottom-->
</div><!--.container-fluid-->
#
