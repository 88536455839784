import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlockIdComponent } from './flock-id.component';



@NgModule({
    declarations: [
        FlockIdComponent
    ],
    exports: [
        FlockIdComponent
    ],
    imports: [
        CommonModule
    ]
})
export class FlockIdModule { }
