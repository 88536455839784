import {Injectable} from '@angular/core';
import {TimelineService} from "./timeline.service";
import {DateTimeService} from "./date-time.service";

@Injectable({
  providedIn: 'root'
})
export class HeightService {

  constructor(
    private timelineService: TimelineService,
    private dateTimeService: DateTimeService
  ) { }

  /**
   * Returns pixels based of date range
   * @param start
   * @param end
   */
  getRangeHeightInPixels(start: Date, end: Date): number {
    const height = this.getRangeLengthInIntervals(
      start,
      end,
      this.timelineService.minutesSplit$.value
    );
    return (this.timelineService.timeHeight * height) + 1;
  }

  /**
   * Returns number of intervals of date range
   * @param start
   * @param end
   * @param timeLength
   * @param snapToIntervalSize
   */
  getRangeLengthInIntervals(
    start: Date,
    end: Date,
    timeLength: number,
    snapToIntervalSize?: boolean
  ): number {
    const lengthInMinutes = this.dateTimeService.getDuration(start, end).valueOf() / 1000 / 60;
    const intervalDuration = lengthInMinutes / timeLength;
    return snapToIntervalSize
      ? Math.round(intervalDuration) * timeLength
      : intervalDuration;
  }
}
