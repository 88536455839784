<div id="schedule-container" class="w-100" *ngIf="timeRange && shifts">
  <cdk-virtual-scroll-viewport appendOnly itemSize="{{timeRange.length}}">
    <div
      id="schedule"
    >
      <div id="time-line">
        <div
          class="shift-padding"
          [style.height.px]="startDate|buffer:shifts[0].startDateTime: timelineService.timeHeight"
        >
        </div>
        <ng-container *cdkVirtualFor="let rangeInterval of timeRange; index as timeIndex">
          <app-time
            [style.height.px]="timeHeight"
            [style.max-height.px]="timeHeight"
            [style.min-height.px]="timeHeight"
            [timeInterval]="rangeInterval"
            [timeHeight]="timeHeight"
            [timeDisplay]="rangeInterval | time"
            *ngIf="timeIndex !== timeRange.length -1"
          ></app-time>
        </ng-container>
        <div
          class="shift-padding"
          [style.height.px]="shifts[shifts.length-1].endDateTime|buffer:endDate: timelineService.timeHeight"
          [style.bottom.px]="0"
        >
        </div>
      </div><!--#time-->

      <div id="schedule-column-container" cdkDropListGroup>
        <div
          class="schedule-shift-column-spacer"
        >
          <ng-container *ngFor="let shift of shifts">
            <div
              [style.height.px]="shift | shiftHeight: timelineService.timeHeight"
              [style.top.px]="shift | shiftTransform : timeHeight:startDate"
              class="shift-container"
            >
              <app-shift [shift]="shift"></app-shift>
            </div>
          </ng-container>
        </div><!--.schedule-shift-column-spacer-->
        <!-- Render columns -->
        <ng-container *ngFor="let line of lines; index as lineIndex">
          <div class="schedule-column-spacer spacer-index-{{lineIndex}}"
          ></div><!--.schedule-column-spacer-->
          <div class="schedule-column"
               cdkDropList
               (cdkDropListDropped)="dropListDropped($event)"
               [id]="line.line.toString()"
               [cdkDropListData]="timelineService.filterProcessLotToLine(line, this.processLotService.processLotSubject$.getValue())"
               [cdkDropListConnectedTo]="getConnectedLines(line)"
          >

            <!-- Render downtime event-->
            <ng-container *ngFor="let downtime of timelineService.filterDownTimeToLine(line, downtimeEvents)">
              <div
                class="downtime-container"
                [style.height.px]="downtime.start | downtimeHeight: downtime.end:timeHeight"
                [style.top.px]="downtime.start | downtimeTransform:startDate:timeHeight"
              >
                <app-down-time [start]="downtime.start" [end]="downtime.end"></app-down-time>
              </div>
            </ng-container>

            <!-- Render exclusions -->
            <ng-container *ngFor="let exclusion of exclusions">
              <div
                class="downtime-container"
                [style.height.px]="exclusion.start | downtimeHeight: exclusion.end:timeHeight"
                [style.top.px]="exclusion.start | downtimeTransform:startDate:timeHeight"
              >
                <app-down-time [start]="exclusion.start" [end]="exclusion.end" [isExclusion]="true"></app-down-time>
              </div>
            </ng-container>

            <!-- Render flock cards-->
            <ng-container
              *ngFor="let processLot of timelineService.filterProcessLotToLine(line, this.processLotService.processLotSubject$.getValue())">
              <div
                class="schedule-item-container"
                [style.height.px]="processLot | processLotHeight : timelineService.timeHeight"
                [style.top.px]="processLot | processLotTransform:timeHeight:startDate"
                [style.width.%]="100"
                cdkDrag
                [cdkDragStartDelay]="1"
                [cdkDragData]="processLot"
              >

                <!-- Click container that doesn't cover the drag indicator -->
                <div class="click" (click)="onClick(processLot)"
                     [ngStyle]="{right: canDrag(processLot.status) ? '45px' : '0px'}"></div>
                <!-- Display process lot in timeline-->
                <app-flock-card
                  [isFiltered]="isFiltered(processLot)"
                  [processLot]="processLot"
                  [style.height.px]="processLot | processLotHeight : timelineService.timeHeight"
                >
                </app-flock-card>

              </div>

            </ng-container>
          </div><!--.schedule-column-->
        </ng-container>


        <app-current-time-marker
          id="current-time-marker"
          *ngIf="currentScheduleDate.getDate()==currentTime.getDate()"
          [currentTime]="currentTime"
          [style.transform]="currentTime | currentTimeTransform:startDate"
        >
        </app-current-time-marker>
      </div><!--#scheduler-column-container-->
    </div><!--#scheduler-->
  </cdk-virtual-scroll-viewport>
</div><!--.container-fluid-->
