import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusDotComponent } from './status-dot.component';
import {CoreModule} from "../../core/core.module";



@NgModule({
    declarations: [
        StatusDotComponent
    ],
    exports: [
        StatusDotComponent
    ],
    imports: [
        CommonModule,
        CoreModule
    ]
})
export class StatusDotModule { }
