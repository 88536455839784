import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipListComponent } from './chip-list.component';
import {MatChipsModule} from "@angular/material/chips";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {Cat2SharedModule} from "@cat2/legacy-meta-cat";
import {CoreModule} from "../../../core/core.module";



@NgModule({
    declarations: [
        ChipListComponent
    ],
    exports: [
        ChipListComponent
    ],
  imports: [
    CommonModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    Cat2SharedModule,
    CoreModule
  ]
})
export class ChipListModule { }
