import { Injectable } from '@angular/core';
import {ViewportScroller} from "@angular/common";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {TimeRange} from "../interfaces/time-range";
import {DateTimeService} from "./date-time.service";
import {TimelineService} from "./timeline.service";
import {BehaviorSubject, Observable} from "rxjs";
import {HeightService} from "./height.service";

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  getSharedTimeRange(): Observable<TimeRange[] | null> {
    return this._sharedTimeRange.asObservable();
  }

  setSharedTimeRange(value: TimeRange[]): void {
    this._sharedTimeRange.next(value)
  }

  setCdkVirtualScrollViewport(viewport: CdkVirtualScrollViewport): void {
    this._sharedCdkVirtualScrollViewport.next(viewport);
  }

  getCdkVirtualScrollViewport(): Observable<CdkVirtualScrollViewport | null> {
    return this._sharedCdkVirtualScrollViewport.asObservable();
  }

  get sharedCurrentTime(): Date {
    return this._sharedCurrentTime;
  }

  private _sharedCurrentTime: Date = new Date();
  private _sharedCdkVirtualScrollViewport: BehaviorSubject<CdkVirtualScrollViewport | null> = new BehaviorSubject<CdkVirtualScrollViewport | null>(null);
  private _sharedTimeRange: BehaviorSubject<TimeRange[] | null> = new BehaviorSubject<TimeRange[] | null>(null);

  constructor(
    private _viewPortScroller: ViewportScroller,
    private dateTimeService: DateTimeService,
    private timelineService: TimelineService,
    private heightService: HeightService
  ) { }

  scrollToElement(target: string) {
    this._viewPortScroller.scrollToAnchor(target);
  }

  /**
   * Scroll to current time using CdkVirtualScrollViewport
   */
  scrollToCurrentTime(currentTime: Date, virtualScrollViewport: CdkVirtualScrollViewport, timeRange: TimeRange[]) {
    // Get current time
    const currentTimeOfMarker = currentTime;

    if (currentTimeOfMarker) {
      // IMPORTANT: Wait for DOM to finish loading, so we have access to the CdkVirtualScrollViewport
      setTimeout(() => {
        // The offset we want to subtract from the scroll height
        const offset: number = 50;

        // calculate the pixel height 
        let pixels = this.heightService.getRangeHeightInPixels(
          timeRange[0]?.startDate,
          currentTime
        )
        const scrollPixels = pixels-offset;
        // Scroll to the pixel calculation smoothly
        virtualScrollViewport?.scrollTo({top: scrollPixels, behavior: 'smooth'});
      });
    }
  }
}
