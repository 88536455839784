<legacy-meta-cat-row-action
  [icon]="'more_horiz'"
  (actionClicked)="onMoreMenuClick()"
  #menuTrigger
  [matMenuTriggerFor]="menu"
></legacy-meta-cat-row-action>
<mat-menu #menu="matMenu">
  <div
    [matTooltip]="isCompleteDisable() ? 'Only Hanging or Online Process lots can be marked as Complete':''"
    [matTooltipDisabled]="!isCompleteDisable()"
    matTooltipPosition="right"
  >
    <button mat-menu-item (click)="onComplete()" [disabled]="isCompleteDisable()">
      Complete
    </button>
  </div>
  <button mat-menu-item (click)="onAttribute()">Attribute</button>
  <button mat-menu-item (click)="onDateChange()">Change Date</button>

  <div
    [matTooltip]="isPostAndCountDisable() ? 'Only Complete Process lots can set the official count':''"
    [matTooltipDisabled]="!isPostAndCountDisable()"
    matTooltipPosition="right">
    <button mat-menu-item (click)="onSetOfficialCount()" [disabled]="isPostAndCountDisable()">Set Official Count</button>
  </div>
  <div
    [matTooltip]="isPostAndCountDisable() ? 'Only Complete Process lots can be marked as Posted':''"
    [matTooltipDisabled]="!isPostAndCountDisable()"
    matTooltipPosition="right">
    <button mat-menu-item (click)="onPost()" [disabled]="isPostAndCountDisable()">Post</button>
  </div>
  <div
    [matTooltip]="isDeleteEnabled() ?'Only Scheduled Process lots can be deleted':''"
    [matTooltipDisabled]="!isDeleteEnabled()"
    matTooltipPosition="right">
    <button mat-menu-item (click)="onDelete()" [disabled]="isDeleteEnabled()">Delete</button>
  </div>
</mat-menu>

<ng-template #changeDateDialog>
</ng-template>
