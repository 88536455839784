<mat-dialog-content>
  <h2 class="fw-bold">Change Manufacture Date</h2>
  <button matTooltip="Select Date" class="cat2-time-table-toolbar-large-text" (click)="picker.open()" mat-button>
    <input type="hidden" [value]="date" (dateChange)="changeDate($event.value)" [matDatepicker]="picker"/>
    <mat-datepicker touchUi #picker></mat-datepicker>
    {{ date | date: "mediumDate" }}
  </button>
  <div class="class-form-actions">
    <button mat-raised-button color="primary" (click)="onConfirmClick()">Confirm</button>
    <button mat-raised-button color="accent" (click)="onCancelClick()">Cancel</button>
  </div>
</mat-dialog-content>
