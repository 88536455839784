import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {DatePipe} from "@angular/common";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-current-time-marker',
  templateUrl: './current-time-marker.component.html',
  styleUrls: ['./current-time-marker.component.scss'],
  providers: [DatePipe]
})
export class CurrentTimeMarkerComponent implements OnInit, OnChanges {
  @Input() currentTime!:Date;

  currentTimeSubscription: Subscription = new Subscription();
  currentTimeText!: string | null;

  constructor(
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
    this.currentTimeText = this.datePipe.transform(this.currentTime, 'shortTime');
  }



}
