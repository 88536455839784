import { Component, OnInit, Input } from '@angular/core';
import {DatePickerService} from "../../../core/services/date-picker.service";

@Component({
  selector: 'app-today',
  templateUrl: './today.component.html',
  styleUrls: ['./today.component.css']
})
export class TodayComponent implements OnInit {
  @Input() isHaulingPath: boolean = false;

  constructor(
    private schedulerStateService :DatePickerService
  ) { }

  ngOnInit(): void {
  }

  setToday(){
    if(!this.isHaulingPath) {
      this.schedulerStateService.setCurrentScheduleDate(new Date());
    }
    else{
      this.schedulerStateService.setCurrentScheduleDate(new Date(), 'hauling-queue');
    }
  }
}
