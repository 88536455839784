<div class="time-table-toolbar">
  <time-table-toolbar [isHaulingPath]="true"></time-table-toolbar>
</div>
<legacy-meta-cat-data-grid
    *ngIf="dataSource && metadata"
    [dataSource]="dataSource"
    [metadata]="metadata"
    [padding]="14"
    [rowActionTemplate]="rowActions"
></legacy-meta-cat-data-grid>
<div style="flex: 1"></div>
<mat-paginator
  [length]="this.totalItems"
  [pageSize]="this.pageSize"
  [pageSizeOptions]="[10,25,50]"
  (page)="onPageChange($event)"
  aria-label="Select a page of logging results"
></mat-paginator>

<ng-template #rowActions let-data="data">
  <app-grid-menu [data]="data"
                 (onDeleteData)="onDelete($event)"
                 (onUpdateData)="updateDataSource($event)"
                 (onUpdateAttribute)="loadDataSource()">
  </app-grid-menu>
</ng-template>

