<button
  mat-icon-button
  aria-label="Filter"
  matTooltip="Filter"
  id="filter-button"
  class="v-align-middle"
  (click)="onClick()"
>
  <mat-icon>filter_alt_outline</mat-icon>
</button>
