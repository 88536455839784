import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlockStatusPipe } from './pipes/flock-status.pipe';
import { TimePipe } from './pipes/time.pipe';
import { ProcessLotHeightPipe } from './pipes/process-lot-height.pipe';
import { ProcessLotTransformPipe } from './pipes/process-lot-transform.pipe';
import { DowntimeHeightPipe } from './pipes/downtime-height.pipe';
import { DowntimeTransformPipe } from './pipes/downtime-transform.pipe';
import { CurrentTimeTransformPipe } from './pipes/current-time-transform.pipe';
import { ProcessLotIconsPipe } from './pipes/process-lot-icons.pipe';
import {ProcessLotsClient} from "./services/live-receiving.swagger";
import { BufferPipe } from './pipes/buffer.pipe';

@NgModule({
    declarations: [
        FlockStatusPipe,
        TimePipe,
        ProcessLotHeightPipe,
        ProcessLotTransformPipe,
        DowntimeHeightPipe,
        DowntimeTransformPipe,
        CurrentTimeTransformPipe,
        ProcessLotIconsPipe,
        BufferPipe
    ],
    exports: [
        FlockStatusPipe,
        TimePipe,
        ProcessLotHeightPipe,
        ProcessLotTransformPipe,
        DowntimeHeightPipe,
        DowntimeTransformPipe,
        CurrentTimeTransformPipe,
        ProcessLotIconsPipe,
        BufferPipe
    ],
  imports: [
    CommonModule
  ],
  providers: [
    ProcessLotsClient
  ]

})
export class CoreModule { }
