import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: Date; output: Date; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: Date; output: Date; }
  /** The built-in `Decimal` scalar type. */
  Decimal: { input: number; output: number; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: number; output: number; }
  /** The `Short` scalar type represents non-fractional signed whole 16-bit numeric values. Short can represent values between -(2^15) and 2^15 - 1. */
  Short: { input: number; output: number; }
  UUID: { input: string; output: string; }
  /** The UnsignedLong scalar type represents a unsigned 64-bit numeric non-fractional value greater than or equal to 0. */
  UnsignedLong: { input: number; output: number; }
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type AttributeClass = {
  __typename?: 'AttributeClass';
  key: Scalars['UUID']['output'];
  value: Scalars['String']['output'];
};

export type AttributeTemplate = {
  __typename?: 'AttributeTemplate';
  attributeClass: AttributeClass;
  dataType: DataType;
  deliveryClass: DeliveryClass;
  enabled: Scalars['Boolean']['output'];
  key: Scalars['UUID']['output'];
  possibleValues: Array<PossibleValueModel>;
  required: Scalars['Boolean']['output'];
  sequence: Scalars['Int']['output'];
  system: Scalars['Boolean']['output'];
  validation?: Maybe<Scalars['String']['output']>;
  weighClass: WeighClass;
};

export type AttributeValue = {
  __typename?: 'AttributeValue';
  attributeClass?: Maybe<Scalars['String']['output']>;
  dataType: DataType;
  deliveryClass?: Maybe<Scalars['String']['output']>;
  guid: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  possibleValues?: Maybe<Array<Maybe<PossibleValue>>>;
  required: Scalars['Boolean']['output'];
  sequence: Scalars['Int']['output'];
  system: Scalars['Boolean']['output'];
  validationRegex?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  weighClass?: Maybe<Scalars['String']['output']>;
};

export type AttributeValueModel = {
  __typename?: 'AttributeValueModel';
  attributeTemplate?: Maybe<AttributeTemplate>;
  attributeTemplateKey: Scalars['UUID']['output'];
  key: Scalars['UUID']['output'];
  ticket?: Maybe<TicketModel>;
  ticketKey: Scalars['UUID']['output'];
  value: Scalars['String']['output'];
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Classifications = {
  __typename?: 'Classifications';
  classification?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  guid: Scalars['UUID']['output'];
};

export type ClassificationsSortInput = {
  classification?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  guid?: InputMaybe<SortEnumType>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export enum CountType {
  AverageWeight = 'AVERAGE_WEIGHT',
  Count = 'COUNT',
  ScaleWeight = 'SCALE_WEIGHT'
}

export type CreateIntegrationSubscriptionDataInput = {
  batchdate: Scalars['DateTime']['input'];
  data?: InputMaybe<Scalars['String']['input']>;
  dataitemguid: Scalars['UUID']['input'];
  manufacturedate: Scalars['DateTime']['input'];
  shift: Scalars['Int']['input'];
  siteguid: Scalars['UUID']['input'];
};

export type CreateIntegrationSubscriptionDataSummaryInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  dataitemguid: Scalars['UUID']['input'];
  serial: Scalars['Int']['input'];
  siteguid: Scalars['UUID']['input'];
};

export type CreateIntegrationSubscriptionDataWriterInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  dataitemguid: Scalars['UUID']['input'];
  serial: Scalars['Int']['input'];
  siteguid: Scalars['UUID']['input'];
};

export type CreateIntegrationSubscriptionGroupInput = {
  active: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  groupclass?: InputMaybe<Scalars['String']['input']>;
  instanceguid: Scalars['UUID']['input'];
  interval: Scalars['Long']['input'];
  serial: Scalars['Int']['input'];
  siteguid: Scalars['UUID']['input'];
};

export type CreateIntegrationSubscriptionInstanceInput = {
  autostart: Scalars['Boolean']['input'];
  connectionlibrary?: InputMaybe<Scalars['String']['input']>;
  connectlocal: Scalars['Boolean']['input'];
  counter?: InputMaybe<Scalars['Short']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  ip?: InputMaybe<Scalars['String']['input']>;
  isda: Scalars['Boolean']['input'];
  line?: InputMaybe<Scalars['Short']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  port: Scalars['Int']['input'];
  serial: Scalars['Int']['input'];
  siteguid: Scalars['UUID']['input'];
};

export type CreateIntegrationSubscriptionSettingInput = {
  active: Scalars['Boolean']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['String']['input']>;
  serial: Scalars['Int']['input'];
  siteguid: Scalars['UUID']['input'];
  value: Scalars['Int']['input'];
};

export type CreateIntegrationSubscriptionTagInput = {
  active: Scalars['Boolean']['input'];
  configuration?: InputMaybe<Scalars['String']['input']>;
  counter?: InputMaybe<Scalars['String']['input']>;
  departmentid?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  downtimetag?: InputMaybe<Scalars['Boolean']['input']>;
  itemaction?: InputMaybe<Scalars['String']['input']>;
  lineid?: InputMaybe<Scalars['Int']['input']>;
  linkednodeguid?: InputMaybe<Scalars['UUID']['input']>;
  nodeid?: InputMaybe<Scalars['String']['input']>;
  serial: Scalars['Int']['input'];
  siteguid: Scalars['UUID']['input'];
  subscriptiongroupguid: Scalars['UUID']['input'];
  tagcategory?: InputMaybe<Scalars['String']['input']>;
  tagdata?: InputMaybe<Scalars['String']['input']>;
  tagtypeguid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateScheduledProcessLotRequestInput = {
  catchCount: Scalars['Decimal']['input'];
  endDateTime: Scalars['DateTime']['input'];
  growerLot?: InputMaybe<Scalars['String']['input']>;
  line: Scalars['Short']['input'];
  scheduledDate: Scalars['DateTime']['input'];
  specifiedGuid: Scalars['UUID']['input'];
  startDateTime: Scalars['DateTime']['input'];
  ticketGuid: Scalars['UUID']['input'];
};

export enum DataType {
  Bool = 'BOOL',
  DateTime = 'DATE_TIME',
  Decimal = 'DECIMAL',
  Integer = 'INTEGER',
  Lookup = 'LOOKUP',
  String = 'STRING'
}

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeleteIntegrationSubscriptionDataInput = {
  guid: Scalars['UUID']['input'];
};

export type DeleteIntegrationSubscriptionDataSummaryInput = {
  serial: Scalars['Int']['input'];
};

export type DeleteIntegrationSubscriptionDataWriterInput = {
  serial: Scalars['Int']['input'];
};

export type DeleteIntegrationSubscriptionGroupInput = {
  serial: Scalars['Int']['input'];
};

export type DeleteIntegrationSubscriptionInstanceInput = {
  serial: Scalars['Int']['input'];
};

export type DeleteIntegrationSubscriptionSettingInput = {
  serial: Scalars['Int']['input'];
};

export type DeleteIntegrationSubscriptionTagInput = {
  serial: Scalars['Int']['input'];
};

/** A segment of a collection. */
export type DeliveriesCollectionSegment = {
  __typename?: 'DeliveriesCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Delivery>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
};

export type Delivery = {
  __typename?: 'Delivery';
  averageWeight: Scalars['Decimal']['output'];
  estimatedEndTime: Scalars['DateTime']['output'];
  flock?: Maybe<Flock>;
  flockKey: Scalars['UUID']['output'];
  growerLot?: Maybe<GrowerLotModel>;
  growerLotKey: Scalars['UUID']['output'];
  id: Scalars['String']['output'];
  key: Scalars['UUID']['output'];
  plannedLine: Scalars['Short']['output'];
  scheduledDateTime: Scalars['DateTime']['output'];
  status?: Maybe<DeliveryStatus>;
  tickets?: Maybe<TicketsCollectionSegment>;
  totalBirds: Scalars['Int']['output'];
  transportSchedule?: Maybe<TransportSchedule>;
  transportScheduleKey: Scalars['UUID']['output'];
};


export type DeliveryTicketsArgs = {
  filter?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  ticketType?: InputMaybe<TicketType>;
};

export type DeliveryClass = {
  __typename?: 'DeliveryClass';
  key: Scalars['UUID']['output'];
  value: Scalars['String']['output'];
};

export enum DeliveryStatus {
  Planned = 'PLANNED'
}

export type DepartmentModel = {
  __typename?: 'DepartmentModel';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  key: Scalars['UUID']['output'];
  spans: Array<DepartmentSpanModel>;
  startOfDaySeconds: Scalars['Long']['output'];
};

export type DepartmentSpanModel = {
  __typename?: 'DepartmentSpanModel';
  classification: SpanClassifications;
  description: Scalars['String']['output'];
  durationSeconds: Scalars['UnsignedLong']['output'];
  id: Scalars['String']['output'];
  key: Scalars['UUID']['output'];
  sequence: Scalars['Short']['output'];
  startOffsetSeconds: Scalars['UnsignedLong']['output'];
};

export type FarmMastRecord = {
  __typename?: 'FarmMastRecord';
  addCode?: Maybe<Scalars['String']['output']>;
  farmCity?: Maybe<Scalars['String']['output']>;
  farmName?: Maybe<Scalars['String']['output']>;
  farmNo?: Maybe<Scalars['String']['output']>;
  farmState?: Maybe<Scalars['String']['output']>;
  growerFirstname?: Maybe<Scalars['String']['output']>;
  growerLastname?: Maybe<Scalars['String']['output']>;
  growerReference?: Maybe<Scalars['String']['output']>;
  growoutArea?: Maybe<Scalars['String']['output']>;
  guid: Scalars['UUID']['output'];
};

export type FarmMastRecordSortInput = {
  addCode?: InputMaybe<SortEnumType>;
  farmCity?: InputMaybe<SortEnumType>;
  farmName?: InputMaybe<SortEnumType>;
  farmNo?: InputMaybe<SortEnumType>;
  farmState?: InputMaybe<SortEnumType>;
  growerFirstname?: InputMaybe<SortEnumType>;
  growerLastname?: InputMaybe<SortEnumType>;
  growerReference?: InputMaybe<SortEnumType>;
  growoutArea?: InputMaybe<SortEnumType>;
  guid?: InputMaybe<SortEnumType>;
};

export type FilterValues = {
  __typename?: 'FilterValues';
  classifications: Array<Scalars['String']['output']>;
  growerLotIds: Array<Scalars['String']['output']>;
  growerReferences: Array<Scalars['String']['output']>;
  processLotIds: Array<Scalars['String']['output']>;
};

export type Flock = {
  __typename?: 'Flock';
  availableBirds?: Maybe<Scalars['Int']['output']>;
  breed?: Maybe<Scalars['String']['output']>;
  classification?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  diseasePresent: Scalars['Boolean']['output'];
  farmName?: Maybe<Scalars['String']['output']>;
  farmNumber?: Maybe<Scalars['String']['output']>;
  farmRegion?: Maybe<Scalars['String']['output']>;
  flockId?: Maybe<Scalars['String']['output']>;
  flockSource?: Maybe<FlockSource>;
  gender?: Maybe<Gender>;
  growerKey: Scalars['UUID']['output'];
  growerName?: Maybe<Scalars['String']['output']>;
  growerReference?: Maybe<Scalars['String']['output']>;
  hatchDate?: Maybe<Scalars['DateTime']['output']>;
  houseKeys: Array<Scalars['UUID']['output']>;
  houses: Array<House>;
  key: Scalars['UUID']['output'];
  placementDateTime?: Maybe<Scalars['DateTime']['output']>;
  rationCode?: Maybe<Scalars['String']['output']>;
  regionKey: Scalars['UUID']['output'];
  status?: Maybe<Scalars['String']['output']>;
  totalBirds?: Maybe<Scalars['Int']['output']>;
  weightExpected?: Maybe<Scalars['Decimal']['output']>;
};

export enum FlockSource {
  Contracted = 'CONTRACTED',
  Other = 'OTHER',
  Owned = 'OWNED'
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Mixed = 'MIXED'
}

export type Grower = {
  __typename?: 'Grower';
  address?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  erpReference?: Maybe<Scalars['String']['output']>;
  gradeName?: Maybe<Scalars['String']['output']>;
  growerReference?: Maybe<Scalars['String']['output']>;
  growerType?: Maybe<Scalars['String']['output']>;
  guid: Scalars['UUID']['output'];
  loadAdjustment?: Maybe<Scalars['Decimal']['output']>;
  processId?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  replicationDateTime?: Maybe<Scalars['DateTime']['output']>;
  serviceMan?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  useStandard: Scalars['Boolean']['output'];
};

export type GrowerLot = {
  __typename?: 'GrowerLot';
  flockGuid: Scalars['UUID']['output'];
  growerGuid: Scalars['UUID']['output'];
  growerLotId?: Maybe<Scalars['String']['output']>;
  guid: Scalars['UUID']['output'];
  manufactureDate: Scalars['DateTime']['output'];
  siteGuid: Scalars['UUID']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

export type GrowerLotModel = {
  __typename?: 'GrowerLotModel';
  flockId: Scalars['UUID']['output'];
  growerId: Scalars['UUID']['output'];
  growerLotId: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  manufactureDate: Scalars['Date']['output'];
  status: Scalars['String']['output'];
};

export type GrowerLotSortInput = {
  flockGuid?: InputMaybe<SortEnumType>;
  growerGuid?: InputMaybe<SortEnumType>;
  growerLotId?: InputMaybe<SortEnumType>;
  guid?: InputMaybe<SortEnumType>;
  manufactureDate?: InputMaybe<SortEnumType>;
  siteGuid?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export type GrowerSortInput = {
  address?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  erpReference?: InputMaybe<SortEnumType>;
  gradeName?: InputMaybe<SortEnumType>;
  growerReference?: InputMaybe<SortEnumType>;
  growerType?: InputMaybe<SortEnumType>;
  guid?: InputMaybe<SortEnumType>;
  loadAdjustment?: InputMaybe<SortEnumType>;
  processId?: InputMaybe<SortEnumType>;
  region?: InputMaybe<SortEnumType>;
  replicationDateTime?: InputMaybe<SortEnumType>;
  serviceMan?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  useStandard?: InputMaybe<SortEnumType>;
};

export type House = {
  __typename?: 'House';
  id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sequence: Scalars['Int']['output'];
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type IntegrationSubscriptionData = {
  __typename?: 'IntegrationSubscriptionData';
  batchdate: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['String']['output']>;
  dataitemguid: Scalars['UUID']['output'];
  guid: Scalars['UUID']['output'];
  manufacturedate: Scalars['DateTime']['output'];
  shift: Scalars['Int']['output'];
  siteguid: Scalars['UUID']['output'];
};

export type IntegrationSubscriptionDataFilterInput = {
  and?: InputMaybe<Array<IntegrationSubscriptionDataFilterInput>>;
  batchdate?: InputMaybe<DateTimeOperationFilterInput>;
  data?: InputMaybe<StringOperationFilterInput>;
  dataitemguid?: InputMaybe<UuidOperationFilterInput>;
  manufacturedate?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<IntegrationSubscriptionDataFilterInput>>;
  shift?: InputMaybe<IntOperationFilterInput>;
  siteguid?: InputMaybe<UuidOperationFilterInput>;
};

/** A segment of a collection. */
export type IntegrationSubscriptionDataPagedCollectionSegment = {
  __typename?: 'IntegrationSubscriptionDataPagedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<IntegrationSubscriptionData>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type IntegrationSubscriptionDataSortInput = {
  batchdate?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  dataitemguid?: InputMaybe<SortEnumType>;
  manufacturedate?: InputMaybe<SortEnumType>;
  shift?: InputMaybe<SortEnumType>;
  siteguid?: InputMaybe<SortEnumType>;
};

export type IntegrationSubscriptionDataSummary = {
  __typename?: 'IntegrationSubscriptionDataSummary';
  data?: Maybe<Scalars['String']['output']>;
  dataitemguid: Scalars['UUID']['output'];
  serial: Scalars['Int']['output'];
  siteguid: Scalars['UUID']['output'];
};

export type IntegrationSubscriptionDataSummaryFilterInput = {
  and?: InputMaybe<Array<IntegrationSubscriptionDataSummaryFilterInput>>;
  data?: InputMaybe<StringOperationFilterInput>;
  dataitemguid?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<IntegrationSubscriptionDataSummaryFilterInput>>;
  serial?: InputMaybe<IntOperationFilterInput>;
  siteguid?: InputMaybe<UuidOperationFilterInput>;
};

/** A segment of a collection. */
export type IntegrationSubscriptionDataSummaryPagedCollectionSegment = {
  __typename?: 'IntegrationSubscriptionDataSummaryPagedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<IntegrationSubscriptionDataSummary>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type IntegrationSubscriptionDataSummarySortInput = {
  data?: InputMaybe<SortEnumType>;
  dataitemguid?: InputMaybe<SortEnumType>;
  serial?: InputMaybe<SortEnumType>;
  siteguid?: InputMaybe<SortEnumType>;
};

export type IntegrationSubscriptionDataWriter = {
  __typename?: 'IntegrationSubscriptionDataWriter';
  data?: Maybe<Scalars['String']['output']>;
  dataitemguid: Scalars['UUID']['output'];
  serial: Scalars['Int']['output'];
  siteguid: Scalars['UUID']['output'];
};

export type IntegrationSubscriptionDataWriterFilterInput = {
  and?: InputMaybe<Array<IntegrationSubscriptionDataWriterFilterInput>>;
  data?: InputMaybe<StringOperationFilterInput>;
  dataitemguid?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<IntegrationSubscriptionDataWriterFilterInput>>;
  serial?: InputMaybe<IntOperationFilterInput>;
  siteguid?: InputMaybe<UuidOperationFilterInput>;
};

/** A segment of a collection. */
export type IntegrationSubscriptionDataWriterPagedCollectionSegment = {
  __typename?: 'IntegrationSubscriptionDataWriterPagedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<IntegrationSubscriptionDataWriter>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type IntegrationSubscriptionDataWriterSortInput = {
  data?: InputMaybe<SortEnumType>;
  dataitemguid?: InputMaybe<SortEnumType>;
  serial?: InputMaybe<SortEnumType>;
  siteguid?: InputMaybe<SortEnumType>;
};

export type IntegrationSubscriptionGroup = {
  __typename?: 'IntegrationSubscriptionGroup';
  active: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  groupclass?: Maybe<Scalars['String']['output']>;
  instanceguid: Scalars['UUID']['output'];
  interval: Scalars['Long']['output'];
  serial: Scalars['Int']['output'];
  siteguid: Scalars['UUID']['output'];
};

export type IntegrationSubscriptionGroupFilterInput = {
  active?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<IntegrationSubscriptionGroupFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  groupclass?: InputMaybe<StringOperationFilterInput>;
  instanceguid?: InputMaybe<UuidOperationFilterInput>;
  interval?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<IntegrationSubscriptionGroupFilterInput>>;
  serial?: InputMaybe<IntOperationFilterInput>;
  siteguid?: InputMaybe<UuidOperationFilterInput>;
};

/** A segment of a collection. */
export type IntegrationSubscriptionGroupPagedCollectionSegment = {
  __typename?: 'IntegrationSubscriptionGroupPagedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<IntegrationSubscriptionGroup>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type IntegrationSubscriptionGroupSortInput = {
  active?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  groupclass?: InputMaybe<SortEnumType>;
  instanceguid?: InputMaybe<SortEnumType>;
  interval?: InputMaybe<SortEnumType>;
  serial?: InputMaybe<SortEnumType>;
  siteguid?: InputMaybe<SortEnumType>;
};

export type IntegrationSubscriptionInstance = {
  __typename?: 'IntegrationSubscriptionInstance';
  autostart: Scalars['Boolean']['output'];
  connectionlibrary?: Maybe<Scalars['String']['output']>;
  connectlocal: Scalars['Boolean']['output'];
  counter?: Maybe<Scalars['Short']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  isda: Scalars['Boolean']['output'];
  line?: Maybe<Scalars['Short']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  port: Scalars['Int']['output'];
  serial: Scalars['Int']['output'];
  siteguid: Scalars['UUID']['output'];
};

export type IntegrationSubscriptionInstanceFilterInput = {
  and?: InputMaybe<Array<IntegrationSubscriptionInstanceFilterInput>>;
  autostart?: InputMaybe<BooleanOperationFilterInput>;
  connectionlibrary?: InputMaybe<StringOperationFilterInput>;
  connectlocal?: InputMaybe<BooleanOperationFilterInput>;
  counter?: InputMaybe<ShortOperationFilterInput>;
  department?: InputMaybe<StringOperationFilterInput>;
  ip?: InputMaybe<StringOperationFilterInput>;
  isda?: InputMaybe<BooleanOperationFilterInput>;
  line?: InputMaybe<ShortOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<IntegrationSubscriptionInstanceFilterInput>>;
  port?: InputMaybe<IntOperationFilterInput>;
  serial?: InputMaybe<IntOperationFilterInput>;
  siteguid?: InputMaybe<UuidOperationFilterInput>;
};

/** A segment of a collection. */
export type IntegrationSubscriptionInstancePagedCollectionSegment = {
  __typename?: 'IntegrationSubscriptionInstancePagedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<IntegrationSubscriptionInstance>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type IntegrationSubscriptionInstanceSortInput = {
  autostart?: InputMaybe<SortEnumType>;
  connectionlibrary?: InputMaybe<SortEnumType>;
  connectlocal?: InputMaybe<SortEnumType>;
  counter?: InputMaybe<SortEnumType>;
  department?: InputMaybe<SortEnumType>;
  ip?: InputMaybe<SortEnumType>;
  isda?: InputMaybe<SortEnumType>;
  line?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  port?: InputMaybe<SortEnumType>;
  serial?: InputMaybe<SortEnumType>;
  siteguid?: InputMaybe<SortEnumType>;
};

export type IntegrationSubscriptionSetting = {
  __typename?: 'IntegrationSubscriptionSetting';
  active: Scalars['Boolean']['output'];
  code?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  serial: Scalars['Int']['output'];
  siteguid: Scalars['UUID']['output'];
  value: Scalars['Int']['output'];
};

export type IntegrationSubscriptionSettingFilterInput = {
  active?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<IntegrationSubscriptionSettingFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  data?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<IntegrationSubscriptionSettingFilterInput>>;
  serial?: InputMaybe<IntOperationFilterInput>;
  siteguid?: InputMaybe<UuidOperationFilterInput>;
  value?: InputMaybe<IntOperationFilterInput>;
};

/** A segment of a collection. */
export type IntegrationSubscriptionSettingPagedCollectionSegment = {
  __typename?: 'IntegrationSubscriptionSettingPagedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<IntegrationSubscriptionSetting>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type IntegrationSubscriptionSettingSortInput = {
  active?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  data?: InputMaybe<SortEnumType>;
  serial?: InputMaybe<SortEnumType>;
  siteguid?: InputMaybe<SortEnumType>;
  value?: InputMaybe<SortEnumType>;
};

export type IntegrationSubscriptionTag = {
  __typename?: 'IntegrationSubscriptionTag';
  active: Scalars['Boolean']['output'];
  configuration?: Maybe<Scalars['String']['output']>;
  counter?: Maybe<Scalars['String']['output']>;
  departmentid?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  downtimetag?: Maybe<Scalars['Boolean']['output']>;
  itemaction?: Maybe<Scalars['String']['output']>;
  lineid?: Maybe<Scalars['Int']['output']>;
  linkednodeguid?: Maybe<Scalars['UUID']['output']>;
  nodeid?: Maybe<Scalars['String']['output']>;
  serial: Scalars['Int']['output'];
  siteguid: Scalars['UUID']['output'];
  subscriptiongroupguid: Scalars['UUID']['output'];
  tagcategory?: Maybe<Scalars['String']['output']>;
  tagdata?: Maybe<Scalars['String']['output']>;
  tagtypeguid?: Maybe<Scalars['UUID']['output']>;
};

export type IntegrationSubscriptionTagFilterInput = {
  active?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<IntegrationSubscriptionTagFilterInput>>;
  configuration?: InputMaybe<StringOperationFilterInput>;
  counter?: InputMaybe<StringOperationFilterInput>;
  departmentid?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  downtimetag?: InputMaybe<BooleanOperationFilterInput>;
  itemaction?: InputMaybe<StringOperationFilterInput>;
  lineid?: InputMaybe<IntOperationFilterInput>;
  linkednodeguid?: InputMaybe<UuidOperationFilterInput>;
  nodeid?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<IntegrationSubscriptionTagFilterInput>>;
  serial?: InputMaybe<IntOperationFilterInput>;
  siteguid?: InputMaybe<UuidOperationFilterInput>;
  subscriptiongroupguid?: InputMaybe<UuidOperationFilterInput>;
  tagcategory?: InputMaybe<StringOperationFilterInput>;
  tagdata?: InputMaybe<StringOperationFilterInput>;
  tagtypeguid?: InputMaybe<UuidOperationFilterInput>;
};

/** A segment of a collection. */
export type IntegrationSubscriptionTagPagedCollectionSegment = {
  __typename?: 'IntegrationSubscriptionTagPagedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<IntegrationSubscriptionTag>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type IntegrationSubscriptionTagSortInput = {
  active?: InputMaybe<SortEnumType>;
  configuration?: InputMaybe<SortEnumType>;
  counter?: InputMaybe<SortEnumType>;
  departmentid?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  downtimetag?: InputMaybe<SortEnumType>;
  itemaction?: InputMaybe<SortEnumType>;
  lineid?: InputMaybe<SortEnumType>;
  linkednodeguid?: InputMaybe<SortEnumType>;
  nodeid?: InputMaybe<SortEnumType>;
  serial?: InputMaybe<SortEnumType>;
  siteguid?: InputMaybe<SortEnumType>;
  subscriptiongroupguid?: InputMaybe<SortEnumType>;
  tagcategory?: InputMaybe<SortEnumType>;
  tagdata?: InputMaybe<SortEnumType>;
  tagtypeguid?: InputMaybe<SortEnumType>;
};

export type JoinProcessLotsRequestInput = {
  baseProcessLotGuid: Scalars['UUID']['input'];
  joinedProcessLotGuids?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type LiveReceivingProcessLot = {
  __typename?: 'LiveReceivingProcessLot';
  actualAverageWeight?: Maybe<Scalars['Decimal']['output']>;
  catchCount: Scalars['Long']['output'];
  children?: Maybe<Array<Scalars['UUID']['output']>>;
  classification?: Maybe<Classifications>;
  defects?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  delivery?: Maybe<Delivery>;
  deliveryKey?: Maybe<Scalars['UUID']['output']>;
  deliveryModuleCount: Scalars['Int']['output'];
  doaType: CountType;
  doaValue: Scalars['Long']['output'];
  farm?: Maybe<FarmMastRecord>;
  grower?: Maybe<Grower>;
  growerLot?: Maybe<Scalars['String']['output']>;
  growerLotObject?: Maybe<GrowerLot>;
  guid: Scalars['UUID']['output'];
  hangCount: Scalars['Long']['output'];
  lastOnLineToday: Scalars['Boolean']['output'];
  line: Scalars['Short']['output'];
  manufactureDate: Scalars['DateTime']['output'];
  officialCount?: Maybe<Scalars['Long']['output']>;
  officialCountSource?: Maybe<OfficialCountSource>;
  originalManufactureDate?: Maybe<Scalars['DateTime']['output']>;
  processLotId?: Maybe<Scalars['String']['output']>;
  processingFinished: Scalars['DateTime']['output'];
  processingSequence: Scalars['Decimal']['output'];
  processingStarted: Scalars['DateTime']['output'];
  queued: Scalars['DateTime']['output'];
  siteGuid: Scalars['UUID']['output'];
  status?: Maybe<Scalars['String']['output']>;
  ticketGuid: Scalars['UUID']['output'];
  ticketNumber?: Maybe<Scalars['String']['output']>;
  ticketStatus?: Maybe<Scalars['String']['output']>;
  trailerID?: Maybe<Scalars['String']['output']>;
  weighTime?: Maybe<Scalars['DateTime']['output']>;
};

export type LiveReceivingProcessLotSortInput = {
  actualAverageWeight?: InputMaybe<SortEnumType>;
  catchCount?: InputMaybe<SortEnumType>;
  classification?: InputMaybe<ClassificationsSortInput>;
  deliveryKey?: InputMaybe<SortEnumType>;
  deliveryModuleCount?: InputMaybe<SortEnumType>;
  doaType?: InputMaybe<SortEnumType>;
  doaValue?: InputMaybe<SortEnumType>;
  farm?: InputMaybe<FarmMastRecordSortInput>;
  grower?: InputMaybe<GrowerSortInput>;
  growerLot?: InputMaybe<SortEnumType>;
  growerLotObject?: InputMaybe<GrowerLotSortInput>;
  guid?: InputMaybe<SortEnumType>;
  hangCount?: InputMaybe<SortEnumType>;
  lastOnLineToday?: InputMaybe<SortEnumType>;
  line?: InputMaybe<SortEnumType>;
  manufactureDate?: InputMaybe<SortEnumType>;
  officialCount?: InputMaybe<SortEnumType>;
  officialCountSource?: InputMaybe<SortEnumType>;
  originalManufactureDate?: InputMaybe<SortEnumType>;
  processLotId?: InputMaybe<SortEnumType>;
  processingFinished?: InputMaybe<SortEnumType>;
  processingSequence?: InputMaybe<SortEnumType>;
  processingStarted?: InputMaybe<SortEnumType>;
  queued?: InputMaybe<SortEnumType>;
  siteGuid?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  ticketGuid?: InputMaybe<SortEnumType>;
  ticketNumber?: InputMaybe<SortEnumType>;
  ticketStatus?: InputMaybe<SortEnumType>;
  trailerID?: InputMaybe<SortEnumType>;
  weighTime?: InputMaybe<SortEnumType>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>;
  gt?: InputMaybe<Scalars['Long']['input']>;
  gte?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  lt?: InputMaybe<Scalars['Long']['input']>;
  lte?: InputMaybe<Scalars['Long']['input']>;
  neq?: InputMaybe<Scalars['Long']['input']>;
  ngt?: InputMaybe<Scalars['Long']['input']>;
  ngte?: InputMaybe<Scalars['Long']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  nlt?: InputMaybe<Scalars['Long']['input']>;
  nlte?: InputMaybe<Scalars['Long']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  attribute: Array<LiveReceivingProcessLot>;
  createIntegrationSubscriptionData?: Maybe<IntegrationSubscriptionData>;
  createIntegrationSubscriptionDataSummary?: Maybe<IntegrationSubscriptionDataSummary>;
  createIntegrationSubscriptionDataWriter?: Maybe<IntegrationSubscriptionDataWriter>;
  createIntegrationSubscriptionGroup?: Maybe<IntegrationSubscriptionGroup>;
  createIntegrationSubscriptionInstance?: Maybe<IntegrationSubscriptionInstance>;
  createIntegrationSubscriptionSetting?: Maybe<IntegrationSubscriptionSetting>;
  createIntegrationSubscriptionTag?: Maybe<IntegrationSubscriptionTag>;
  createProcessLot: Array<LiveReceivingProcessLot>;
  deleteIntegrationSubscriptionData?: Maybe<IntegrationSubscriptionData>;
  deleteIntegrationSubscriptionDataSummary?: Maybe<IntegrationSubscriptionDataSummary>;
  deleteIntegrationSubscriptionDataWriter?: Maybe<IntegrationSubscriptionDataWriter>;
  deleteIntegrationSubscriptionGroup?: Maybe<IntegrationSubscriptionGroup>;
  deleteIntegrationSubscriptionInstance?: Maybe<IntegrationSubscriptionInstance>;
  deleteIntegrationSubscriptionSetting?: Maybe<IntegrationSubscriptionSetting>;
  deleteIntegrationSubscriptionTag?: Maybe<IntegrationSubscriptionTag>;
  deleteProcessLot: Array<LiveReceivingProcessLot>;
  joinLots: Array<LiveReceivingProcessLot>;
  selectLastLot: Array<LiveReceivingProcessLot>;
  splitLot: Array<LiveReceivingProcessLot>;
  updateDoaCount: Scalars['Boolean']['output'];
  updateIntegrationSubscriptionData?: Maybe<IntegrationSubscriptionData>;
  updateIntegrationSubscriptionDataSummary?: Maybe<IntegrationSubscriptionDataSummary>;
  updateIntegrationSubscriptionDataWriter?: Maybe<IntegrationSubscriptionDataWriter>;
  updateIntegrationSubscriptionGroup?: Maybe<IntegrationSubscriptionGroup>;
  updateIntegrationSubscriptionInstance?: Maybe<IntegrationSubscriptionInstance>;
  updateIntegrationSubscriptionSetting?: Maybe<IntegrationSubscriptionSetting>;
  updateIntegrationSubscriptionTag?: Maybe<IntegrationSubscriptionTag>;
  updateManufactureDate: Array<LiveReceivingProcessLot>;
  updatePosition: Array<LiveReceivingProcessLot>;
  updateProcessLot: Array<LiveReceivingProcessLot>;
};


export type MutationAttributeArgs = {
  request: UpdateAttributeRequestInput;
};


export type MutationCreateIntegrationSubscriptionDataArgs = {
  input: CreateIntegrationSubscriptionDataInput;
};


export type MutationCreateIntegrationSubscriptionDataSummaryArgs = {
  input: CreateIntegrationSubscriptionDataSummaryInput;
};


export type MutationCreateIntegrationSubscriptionDataWriterArgs = {
  input: CreateIntegrationSubscriptionDataWriterInput;
};


export type MutationCreateIntegrationSubscriptionGroupArgs = {
  input: CreateIntegrationSubscriptionGroupInput;
};


export type MutationCreateIntegrationSubscriptionInstanceArgs = {
  input: CreateIntegrationSubscriptionInstanceInput;
};


export type MutationCreateIntegrationSubscriptionSettingArgs = {
  input: CreateIntegrationSubscriptionSettingInput;
};


export type MutationCreateIntegrationSubscriptionTagArgs = {
  input: CreateIntegrationSubscriptionTagInput;
};


export type MutationCreateProcessLotArgs = {
  request: CreateScheduledProcessLotRequestInput;
};


export type MutationDeleteIntegrationSubscriptionDataArgs = {
  input: DeleteIntegrationSubscriptionDataInput;
};


export type MutationDeleteIntegrationSubscriptionDataSummaryArgs = {
  input: DeleteIntegrationSubscriptionDataSummaryInput;
};


export type MutationDeleteIntegrationSubscriptionDataWriterArgs = {
  input: DeleteIntegrationSubscriptionDataWriterInput;
};


export type MutationDeleteIntegrationSubscriptionGroupArgs = {
  input: DeleteIntegrationSubscriptionGroupInput;
};


export type MutationDeleteIntegrationSubscriptionInstanceArgs = {
  input: DeleteIntegrationSubscriptionInstanceInput;
};


export type MutationDeleteIntegrationSubscriptionSettingArgs = {
  input: DeleteIntegrationSubscriptionSettingInput;
};


export type MutationDeleteIntegrationSubscriptionTagArgs = {
  input: DeleteIntegrationSubscriptionTagInput;
};


export type MutationDeleteProcessLotArgs = {
  guid: Scalars['UUID']['input'];
};


export type MutationJoinLotsArgs = {
  request: JoinProcessLotsRequestInput;
};


export type MutationSelectLastLotArgs = {
  request: SelectLastLotRequestInput;
};


export type MutationSplitLotArgs = {
  request: SplitProcessLotRequestInput;
};


export type MutationUpdateDoaCountArgs = {
  request: UpdateProcessLotDoaCountsRequestInput;
};


export type MutationUpdateIntegrationSubscriptionDataArgs = {
  input: UpdateIntegrationSubscriptionDataInput;
};


export type MutationUpdateIntegrationSubscriptionDataSummaryArgs = {
  input: UpdateIntegrationSubscriptionDataSummaryInput;
};


export type MutationUpdateIntegrationSubscriptionDataWriterArgs = {
  input: UpdateIntegrationSubscriptionDataWriterInput;
};


export type MutationUpdateIntegrationSubscriptionGroupArgs = {
  input: UpdateIntegrationSubscriptionGroupInput;
};


export type MutationUpdateIntegrationSubscriptionInstanceArgs = {
  input: UpdateIntegrationSubscriptionInstanceInput;
};


export type MutationUpdateIntegrationSubscriptionSettingArgs = {
  input: UpdateIntegrationSubscriptionSettingInput;
};


export type MutationUpdateIntegrationSubscriptionTagArgs = {
  input: UpdateIntegrationSubscriptionTagInput;
};


export type MutationUpdateManufactureDateArgs = {
  request: UpdateProcessLotManufactureDateRequestInput;
};


export type MutationUpdatePositionArgs = {
  request: UpdateProcessLotLineRequestInput;
};


export type MutationUpdateProcessLotArgs = {
  request: UpdateProcessLotStatusRequestInput;
};

export enum OfficialCountSource {
  Catch = 'CATCH',
  Hang = 'HANG',
  Manual = 'MANUAL'
}

export type PossibleValue = {
  __typename?: 'PossibleValue';
  id: Scalars['UUID']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type PossibleValueModel = {
  __typename?: 'PossibleValueModel';
  key: Scalars['UUID']['output'];
  sequence: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type ProcessLotsFilterInput = {
  classification?: InputMaybe<Scalars['String']['input']>;
  farmNo?: InputMaybe<Scalars['String']['input']>;
  growerLotId?: InputMaybe<Scalars['String']['input']>;
  growerReference?: InputMaybe<Scalars['String']['input']>;
  line?: InputMaybe<Scalars['Short']['input']>;
  processLot?: InputMaybe<Scalars['UUID']['input']>;
};

/** A segment of a collection. */
export type ProcessLotsForDateCollectionSegment = {
  __typename?: 'ProcessLotsForDateCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<LiveReceivingProcessLot>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  department?: Maybe<DepartmentModel>;
  filterValues: FilterValues;
  integrationSubscriptionData: Array<IntegrationSubscriptionData>;
  integrationSubscriptionDataById?: Maybe<IntegrationSubscriptionData>;
  integrationSubscriptionDataPaged?: Maybe<IntegrationSubscriptionDataPagedCollectionSegment>;
  integrationSubscriptionDataSummary: Array<IntegrationSubscriptionDataSummary>;
  integrationSubscriptionDataSummaryById?: Maybe<IntegrationSubscriptionDataSummary>;
  integrationSubscriptionDataSummaryPaged?: Maybe<IntegrationSubscriptionDataSummaryPagedCollectionSegment>;
  integrationSubscriptionDataWriter: Array<IntegrationSubscriptionDataWriter>;
  integrationSubscriptionDataWriterById?: Maybe<IntegrationSubscriptionDataWriter>;
  integrationSubscriptionDataWriterPaged?: Maybe<IntegrationSubscriptionDataWriterPagedCollectionSegment>;
  integrationSubscriptionGroup: Array<IntegrationSubscriptionGroup>;
  integrationSubscriptionGroupById?: Maybe<IntegrationSubscriptionGroup>;
  integrationSubscriptionGroupPaged?: Maybe<IntegrationSubscriptionGroupPagedCollectionSegment>;
  integrationSubscriptionInstance: Array<IntegrationSubscriptionInstance>;
  integrationSubscriptionInstanceById?: Maybe<IntegrationSubscriptionInstance>;
  integrationSubscriptionInstancePaged?: Maybe<IntegrationSubscriptionInstancePagedCollectionSegment>;
  integrationSubscriptionSetting: Array<IntegrationSubscriptionSetting>;
  integrationSubscriptionSettingById?: Maybe<IntegrationSubscriptionSetting>;
  integrationSubscriptionSettingPaged?: Maybe<IntegrationSubscriptionSettingPagedCollectionSegment>;
  integrationSubscriptionTag: Array<IntegrationSubscriptionTag>;
  integrationSubscriptionTagById?: Maybe<IntegrationSubscriptionTag>;
  integrationSubscriptionTagPaged?: Maybe<IntegrationSubscriptionTagPagedCollectionSegment>;
  processLotById?: Maybe<LiveReceivingProcessLot>;
  processLotsForDate?: Maybe<ProcessLotsForDateCollectionSegment>;
  settingOfficialCountSource: OfficialCountSource;
  ticketsForManufactureDate: Array<Ticket>;
};


export type QueryFilterValuesArgs = {
  manufactureDate: Scalars['DateTime']['input'];
};


export type QueryIntegrationSubscriptionDataArgs = {
  order?: InputMaybe<Array<IntegrationSubscriptionDataSortInput>>;
  where?: InputMaybe<IntegrationSubscriptionDataFilterInput>;
};


export type QueryIntegrationSubscriptionDataByIdArgs = {
  guid: Scalars['UUID']['input'];
};


export type QueryIntegrationSubscriptionDataPagedArgs = {
  order?: InputMaybe<Array<IntegrationSubscriptionDataSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IntegrationSubscriptionDataFilterInput>;
};


export type QueryIntegrationSubscriptionDataSummaryArgs = {
  order?: InputMaybe<Array<IntegrationSubscriptionDataSummarySortInput>>;
  where?: InputMaybe<IntegrationSubscriptionDataSummaryFilterInput>;
};


export type QueryIntegrationSubscriptionDataSummaryByIdArgs = {
  serial: Scalars['Int']['input'];
};


export type QueryIntegrationSubscriptionDataSummaryPagedArgs = {
  order?: InputMaybe<Array<IntegrationSubscriptionDataSummarySortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IntegrationSubscriptionDataSummaryFilterInput>;
};


export type QueryIntegrationSubscriptionDataWriterArgs = {
  order?: InputMaybe<Array<IntegrationSubscriptionDataWriterSortInput>>;
  where?: InputMaybe<IntegrationSubscriptionDataWriterFilterInput>;
};


export type QueryIntegrationSubscriptionDataWriterByIdArgs = {
  serial: Scalars['Int']['input'];
};


export type QueryIntegrationSubscriptionDataWriterPagedArgs = {
  order?: InputMaybe<Array<IntegrationSubscriptionDataWriterSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IntegrationSubscriptionDataWriterFilterInput>;
};


export type QueryIntegrationSubscriptionGroupArgs = {
  order?: InputMaybe<Array<IntegrationSubscriptionGroupSortInput>>;
  where?: InputMaybe<IntegrationSubscriptionGroupFilterInput>;
};


export type QueryIntegrationSubscriptionGroupByIdArgs = {
  serial: Scalars['Int']['input'];
};


export type QueryIntegrationSubscriptionGroupPagedArgs = {
  order?: InputMaybe<Array<IntegrationSubscriptionGroupSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IntegrationSubscriptionGroupFilterInput>;
};


export type QueryIntegrationSubscriptionInstanceArgs = {
  order?: InputMaybe<Array<IntegrationSubscriptionInstanceSortInput>>;
  where?: InputMaybe<IntegrationSubscriptionInstanceFilterInput>;
};


export type QueryIntegrationSubscriptionInstanceByIdArgs = {
  serial: Scalars['Int']['input'];
};


export type QueryIntegrationSubscriptionInstancePagedArgs = {
  order?: InputMaybe<Array<IntegrationSubscriptionInstanceSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IntegrationSubscriptionInstanceFilterInput>;
};


export type QueryIntegrationSubscriptionSettingArgs = {
  order?: InputMaybe<Array<IntegrationSubscriptionSettingSortInput>>;
  where?: InputMaybe<IntegrationSubscriptionSettingFilterInput>;
};


export type QueryIntegrationSubscriptionSettingByIdArgs = {
  serial: Scalars['Int']['input'];
};


export type QueryIntegrationSubscriptionSettingPagedArgs = {
  order?: InputMaybe<Array<IntegrationSubscriptionSettingSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IntegrationSubscriptionSettingFilterInput>;
};


export type QueryIntegrationSubscriptionTagArgs = {
  order?: InputMaybe<Array<IntegrationSubscriptionTagSortInput>>;
  where?: InputMaybe<IntegrationSubscriptionTagFilterInput>;
};


export type QueryIntegrationSubscriptionTagByIdArgs = {
  serial: Scalars['Int']['input'];
};


export type QueryIntegrationSubscriptionTagPagedArgs = {
  order?: InputMaybe<Array<IntegrationSubscriptionTagSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IntegrationSubscriptionTagFilterInput>;
};


export type QueryProcessLotByIdArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryProcessLotsForDateArgs = {
  manufactureDate: Scalars['DateTime']['input'];
  order?: InputMaybe<Array<LiveReceivingProcessLotSortInput>>;
  processLotsFilter: ProcessLotsFilterInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTicketsForManufactureDateArgs = {
  date: Scalars['DateTime']['input'];
};

export type SelectLastLotRequestInput = {
  nextDay?: InputMaybe<Scalars['DateTime']['input']>;
  processLotGuid: Scalars['UUID']['input'];
};

export type SetNullableValueInputOfStringInput = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type SetValueInputOfBooleanInput = {
  value: Scalars['Boolean']['input'];
};

export type SetValueInputOfDateTimeInput = {
  value: Scalars['DateTime']['input'];
};

export type SetValueInputOfGuidInput = {
  value: Scalars['UUID']['input'];
};

export type SetValueInputOfInt32Input = {
  value: Scalars['Int']['input'];
};

export type SetValueInputOfInt64Input = {
  value: Scalars['Long']['input'];
};

export type SetValueInputOfNullableOfBooleanInput = {
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SetValueInputOfNullableOfGuidInput = {
  value?: InputMaybe<Scalars['UUID']['input']>;
};

export type SetValueInputOfNullableOfInt16Input = {
  value?: InputMaybe<Scalars['Short']['input']>;
};

export type SetValueInputOfNullableOfInt32Input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type ShortOperationFilterInput = {
  eq?: InputMaybe<Scalars['Short']['input']>;
  gt?: InputMaybe<Scalars['Short']['input']>;
  gte?: InputMaybe<Scalars['Short']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Short']['input']>>>;
  lt?: InputMaybe<Scalars['Short']['input']>;
  lte?: InputMaybe<Scalars['Short']['input']>;
  neq?: InputMaybe<Scalars['Short']['input']>;
  ngt?: InputMaybe<Scalars['Short']['input']>;
  ngte?: InputMaybe<Scalars['Short']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Short']['input']>>>;
  nlt?: InputMaybe<Scalars['Short']['input']>;
  nlte?: InputMaybe<Scalars['Short']['input']>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SpanClassifications {
  Exclusion = 'EXCLUSION',
  Shift = 'SHIFT'
}

export type SplitProcessLotRequestInput = {
  processLotGuid: Scalars['UUID']['input'];
  quantity: Scalars['Int']['input'];
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Ticket = {
  __typename?: 'Ticket';
  actualAverageWeight: Scalars['Decimal']['output'];
  attributes?: Maybe<Array<Maybe<AttributeValue>>>;
  deliveryGuid: Scalars['UUID']['output'];
  growerLot?: Maybe<Scalars['String']['output']>;
  guid: Scalars['UUID']['output'];
  manufactureDate: Scalars['DateTime']['output'];
  siteGuid: Scalars['UUID']['output'];
  status: TicketStatus;
  ticketNumber?: Maybe<Scalars['String']['output']>;
  trailerId?: Maybe<Scalars['String']['output']>;
  weighTime?: Maybe<Scalars['DateTime']['output']>;
  weights?: Maybe<Array<Maybe<Weight>>>;
};

export type TicketModel = {
  __typename?: 'TicketModel';
  attributeValues: Array<AttributeValueModel>;
  deliveryKey: Scalars['UUID']['output'];
  farmNetWeight?: Maybe<Scalars['Decimal']['output']>;
  growerLotId?: Maybe<Scalars['String']['output']>;
  key: Scalars['UUID']['output'];
  manufactureDate: Scalars['DateTime']['output'];
  plantNetWeight?: Maybe<Scalars['Decimal']['output']>;
  status: TicketStatus;
  ticketNumber: Scalars['String']['output'];
  weights: Array<WeightModel>;
  yardShrink?: Maybe<Scalars['Decimal']['output']>;
};

export enum TicketStatus {
  Active = 'ACTIVE',
  Exception = 'EXCEPTION',
  Finalized = 'FINALIZED',
  Ready = 'READY',
  Scheduled = 'SCHEDULED',
  Void = 'VOID'
}

export enum TicketType {
  Complete = 'COMPLETE',
  Gross = 'GROSS',
  Tare = 'TARE',
  Voided = 'VOIDED',
  YardShrink = 'YARD_SHRINK'
}

/** A segment of a collection. */
export type TicketsCollectionSegment = {
  __typename?: 'TicketsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<TicketModel>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
};

export type TransportSchedule = {
  __typename?: 'TransportSchedule';
  deliveries?: Maybe<DeliveriesCollectionSegment>;
  endDateTime: Scalars['DateTime']['output'];
  hasRejections: Scalars['Boolean']['output'];
  key: Scalars['UUID']['output'];
  manufactureDate: Scalars['Date']['output'];
  startDateTime: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  statusMessage: Scalars['String']['output'];
};


export type TransportScheduleDeliveriesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateAttributeRequestInput = {
  growerLot?: InputMaybe<Scalars['String']['input']>;
  line: Scalars['Short']['input'];
  processLotGuid: Scalars['UUID']['input'];
  ticketGuid: Scalars['UUID']['input'];
};

export enum UpdateCountType {
  AverageWeight = 'AVERAGE_WEIGHT',
  Count = 'COUNT',
  DefaultWeight = 'DEFAULT_WEIGHT',
  ScaleWeight = 'SCALE_WEIGHT'
}

export type UpdateIntegrationSubscriptionDataInput = {
  guid: Scalars['UUID']['input'];
  setbatchdate?: InputMaybe<SetValueInputOfDateTimeInput>;
  setdata?: InputMaybe<SetNullableValueInputOfStringInput>;
  setdataitemguid?: InputMaybe<SetValueInputOfGuidInput>;
  setmanufacturedate?: InputMaybe<SetValueInputOfDateTimeInput>;
  setshift?: InputMaybe<SetValueInputOfInt32Input>;
  setsiteguid?: InputMaybe<SetValueInputOfGuidInput>;
};

export type UpdateIntegrationSubscriptionDataSummaryInput = {
  serial: Scalars['Int']['input'];
  setdata?: InputMaybe<SetNullableValueInputOfStringInput>;
  setdataitemguid?: InputMaybe<SetValueInputOfGuidInput>;
  setsiteguid?: InputMaybe<SetValueInputOfGuidInput>;
};

export type UpdateIntegrationSubscriptionDataWriterInput = {
  serial: Scalars['Int']['input'];
  setdata?: InputMaybe<SetNullableValueInputOfStringInput>;
  setdataitemguid?: InputMaybe<SetValueInputOfGuidInput>;
  setsiteguid?: InputMaybe<SetValueInputOfGuidInput>;
};

export type UpdateIntegrationSubscriptionGroupInput = {
  serial: Scalars['Int']['input'];
  setactive?: InputMaybe<SetValueInputOfBooleanInput>;
  setdescription?: InputMaybe<SetNullableValueInputOfStringInput>;
  setgroupclass?: InputMaybe<SetNullableValueInputOfStringInput>;
  setinstanceguid?: InputMaybe<SetValueInputOfGuidInput>;
  setinterval?: InputMaybe<SetValueInputOfInt64Input>;
  setsiteguid?: InputMaybe<SetValueInputOfGuidInput>;
};

export type UpdateIntegrationSubscriptionInstanceInput = {
  serial: Scalars['Int']['input'];
  setautostart?: InputMaybe<SetValueInputOfBooleanInput>;
  setconnectionlibrary?: InputMaybe<SetNullableValueInputOfStringInput>;
  setconnectlocal?: InputMaybe<SetValueInputOfBooleanInput>;
  setcounter?: InputMaybe<SetValueInputOfNullableOfInt16Input>;
  setdepartment?: InputMaybe<SetNullableValueInputOfStringInput>;
  setip?: InputMaybe<SetNullableValueInputOfStringInput>;
  setisda?: InputMaybe<SetValueInputOfBooleanInput>;
  setline?: InputMaybe<SetValueInputOfNullableOfInt16Input>;
  setname?: InputMaybe<SetNullableValueInputOfStringInput>;
  setport?: InputMaybe<SetValueInputOfInt32Input>;
  setsiteguid?: InputMaybe<SetValueInputOfGuidInput>;
};

export type UpdateIntegrationSubscriptionSettingInput = {
  serial: Scalars['Int']['input'];
  setactive?: InputMaybe<SetValueInputOfBooleanInput>;
  setcode?: InputMaybe<SetNullableValueInputOfStringInput>;
  setdata?: InputMaybe<SetNullableValueInputOfStringInput>;
  setsiteguid?: InputMaybe<SetValueInputOfGuidInput>;
  setvalue?: InputMaybe<SetValueInputOfInt32Input>;
};

export type UpdateIntegrationSubscriptionTagInput = {
  serial: Scalars['Int']['input'];
  setactive?: InputMaybe<SetValueInputOfBooleanInput>;
  setconfiguration?: InputMaybe<SetNullableValueInputOfStringInput>;
  setcounter?: InputMaybe<SetNullableValueInputOfStringInput>;
  setdepartmentid?: InputMaybe<SetNullableValueInputOfStringInput>;
  setdescription?: InputMaybe<SetNullableValueInputOfStringInput>;
  setdowntimetag?: InputMaybe<SetValueInputOfNullableOfBooleanInput>;
  setitemaction?: InputMaybe<SetNullableValueInputOfStringInput>;
  setlineid?: InputMaybe<SetValueInputOfNullableOfInt32Input>;
  setlinkednodeguid?: InputMaybe<SetValueInputOfNullableOfGuidInput>;
  setnodeid?: InputMaybe<SetNullableValueInputOfStringInput>;
  setsiteguid?: InputMaybe<SetValueInputOfGuidInput>;
  setsubscriptiongroupguid?: InputMaybe<SetValueInputOfGuidInput>;
  settagcategory?: InputMaybe<SetNullableValueInputOfStringInput>;
  settagdata?: InputMaybe<SetNullableValueInputOfStringInput>;
  settagtypeguid?: InputMaybe<SetValueInputOfNullableOfGuidInput>;
};

export type UpdateProcessLotDoaCountsRequestInput = {
  doaBirdCount: Scalars['Decimal']['input'];
  doaType?: InputMaybe<UpdateCountType>;
  line: Scalars['Short']['input'];
  processLotKey: Scalars['UUID']['input'];
};

export type UpdateProcessLotLineRequestInput = {
  line: Scalars['Short']['input'];
  processLotGuid: Scalars['UUID']['input'];
  processingStartDateTime: Scalars['DateTime']['input'];
};

export type UpdateProcessLotManufactureDateRequestInput = {
  hangState?: InputMaybe<Scalars['String']['input']>;
  line: Scalars['Short']['input'];
  manufactureDate: Scalars['DateTime']['input'];
  processLotGuid: Scalars['UUID']['input'];
};

export type UpdateProcessLotStatusRequestInput = {
  catchCount?: InputMaybe<Scalars['Decimal']['input']>;
  doaCount?: InputMaybe<Scalars['Decimal']['input']>;
  doaType?: InputMaybe<UpdateCountType>;
  endHangTime: Scalars['DateTime']['input'];
  hangCount?: InputMaybe<Scalars['Decimal']['input']>;
  hangState?: InputMaybe<Scalars['String']['input']>;
  line: Scalars['Short']['input'];
  officialCount?: InputMaybe<Scalars['Decimal']['input']>;
  officialCountSource?: InputMaybe<OfficialCountSource>;
  processLotGuid: Scalars['UUID']['input'];
  startHangTime: Scalars['DateTime']['input'];
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export type WeighClass = {
  __typename?: 'WeighClass';
  key: Scalars['UUID']['output'];
  value: Scalars['String']['output'];
};

export type Weight = {
  __typename?: 'Weight';
  class: WeightClass;
  guid: Scalars['UUID']['output'];
  sequence: Scalars['Int']['output'];
  ticketId: Scalars['UUID']['output'];
  timestampUtc: Scalars['DateTime']['output'];
  value: Scalars['Decimal']['output'];
};

export enum WeightClass {
  Gross = 'GROSS',
  Tare = 'TARE'
}

export type WeightModel = {
  __typename?: 'WeightModel';
  class: WeightClass;
  key: Scalars['UUID']['output'];
  net: Scalars['Decimal']['output'];
  sequence: Scalars['Int']['output'];
  ticket?: Maybe<TicketModel>;
  ticketKey: Scalars['UUID']['output'];
  timestampUtc: Scalars['DateTime']['output'];
  value: Scalars['Decimal']['output'];
  zero?: Maybe<Scalars['Decimal']['output']>;
};

export type DeleteProcessLotMutationVariables = Exact<{
  input: Scalars['UUID']['input'];
}>;


export type DeleteProcessLotMutation = { __typename?: 'Mutation', deleteProcessLot: Array<{ __typename?: 'LiveReceivingProcessLot', growerLot?: string | null }> };

export type GetDepartmentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDepartmentQuery = { __typename?: 'Query', department?: { __typename?: 'DepartmentModel', spans: Array<{ __typename?: 'DepartmentSpanModel', startOffsetSeconds: number, durationSeconds: number, classification: SpanClassifications }> } | null };

export type GetProcessLotsForDateQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  input: Scalars['DateTime']['input'];
  orderBy?: InputMaybe<Array<LiveReceivingProcessLotSortInput> | LiveReceivingProcessLotSortInput>;
}>;


export type GetProcessLotsForDateQuery = { __typename?: 'Query', processLotsForDate?: { __typename?: 'ProcessLotsForDateCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'LiveReceivingProcessLot', actualAverageWeight?: number | null, catchCount: number, defects?: Array<string | null> | null, deliveryModuleCount: number, doaType: CountType, doaValue: number, growerLot?: string | null, guid: string, hangCount: number, line: number, lastOnLineToday: boolean, manufactureDate: Date, originalManufactureDate?: Date | null, officialCount?: number | null, officialCountSource?: OfficialCountSource | null, processingStarted: Date, processingFinished: Date, processLotId?: string | null, processingSequence: number, queued: Date, siteGuid: string, status?: string | null, ticketNumber?: string | null, ticketGuid: string, ticketStatus?: string | null, trailerID?: string | null, weighTime?: Date | null, delivery?: { __typename?: 'Delivery', id: string, flock?: { __typename?: 'Flock', flockId?: string | null, hatchDate?: Date | null, weightExpected?: number | null, breed?: string | null, gender?: Gender | null } | null } | null, grower?: { __typename?: 'Grower', description?: string | null, growerReference?: string | null } | null, farm?: { __typename?: 'FarmMastRecord', farmName?: string | null } | null }> | null } | null };

export type GetSettingOfficialCountSourceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSettingOfficialCountSourceQuery = { __typename?: 'Query', settingOfficialCountSource: OfficialCountSource };

export type GetTicketsForManufactureDateQueryVariables = Exact<{
  input: Scalars['DateTime']['input'];
}>;


export type GetTicketsForManufactureDateQuery = { __typename?: 'Query', ticketsForManufactureDate: Array<{ __typename?: 'Ticket', ticketNumber?: string | null, guid: string }> };

export type UpdateProcessLotManufactureDateMutationVariables = Exact<{
  input: UpdateProcessLotManufactureDateRequestInput;
}>;


export type UpdateProcessLotManufactureDateMutation = { __typename?: 'Mutation', updateManufactureDate: Array<{ __typename?: 'LiveReceivingProcessLot', actualAverageWeight?: number | null, catchCount: number, defects?: Array<string | null> | null, deliveryModuleCount: number, doaValue: number, doaType: CountType, growerLot?: string | null, guid: string, hangCount: number, line: number, lastOnLineToday: boolean, manufactureDate: Date, originalManufactureDate?: Date | null, officialCount?: number | null, officialCountSource?: OfficialCountSource | null, processingStarted: Date, processingFinished: Date, processLotId?: string | null, processingSequence: number, queued: Date, siteGuid: string, status?: string | null, ticketNumber?: string | null, ticketGuid: string, ticketStatus?: string | null, trailerID?: string | null, weighTime?: Date | null, delivery?: { __typename?: 'Delivery', id: string } | null }> };

export type UpdateProcessLotMutationVariables = Exact<{
  input: UpdateProcessLotStatusRequestInput;
}>;


export type UpdateProcessLotMutation = { __typename?: 'Mutation', updateProcessLot: Array<{ __typename?: 'LiveReceivingProcessLot', actualAverageWeight?: number | null, catchCount: number, defects?: Array<string | null> | null, deliveryModuleCount: number, doaType: CountType, doaValue: number, growerLot?: string | null, guid: string, hangCount: number, line: number, lastOnLineToday: boolean, manufactureDate: Date, originalManufactureDate?: Date | null, officialCount?: number | null, officialCountSource?: OfficialCountSource | null, processingStarted: Date, processingFinished: Date, processLotId?: string | null, processingSequence: number, queued: Date, siteGuid: string, status?: string | null, ticketNumber?: string | null, ticketGuid: string, ticketStatus?: string | null, trailerID?: string | null, weighTime?: Date | null, delivery?: { __typename?: 'Delivery', id: string } | null }> };

export type UpdateAttributeMutationVariables = Exact<{
  input: UpdateAttributeRequestInput;
}>;


export type UpdateAttributeMutation = { __typename?: 'Mutation', attribute: Array<{ __typename?: 'LiveReceivingProcessLot', guid: string, manufactureDate: Date }> };

export const DeleteProcessLotDocument = gql`
    mutation DeleteProcessLot($input: UUID!) {
  deleteProcessLot(guid: $input) {
    growerLot
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProcessLotGQL extends Apollo.Mutation<DeleteProcessLotMutation, DeleteProcessLotMutationVariables> {
    override document = DeleteProcessLotDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDepartmentDocument = gql`
    query GetDepartment {
  department {
    spans {
      startOffsetSeconds
      durationSeconds
      classification
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDepartmentGQL extends Apollo.Query<GetDepartmentQuery, GetDepartmentQueryVariables> {
    override document = GetDepartmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProcessLotsForDateDocument = gql`
    query GetProcessLotsForDate($skip: Int, $take: Int, $input: DateTime!, $orderBy: [LiveReceivingProcessLotSortInput!]) {
  processLotsForDate(
    skip: $skip
    take: $take
    manufactureDate: $input
    processLotsFilter: {}
    order: $orderBy
  ) {
    items {
      actualAverageWeight
      catchCount
      defects
      deliveryModuleCount
      delivery {
        id
      }
      doaType
      doaValue
      growerLot
      grower {
        description
        growerReference
      }
      farm {
        farmName
      }
      delivery {
        flock {
          flockId
          hatchDate
          weightExpected
          breed
          gender
        }
      }
      guid
      hangCount
      line
      lastOnLineToday
      manufactureDate
      originalManufactureDate
      officialCount
      officialCountSource
      processingStarted
      processingFinished
      processLotId
      processingSequence
      queued
      siteGuid
      status
      ticketNumber
      ticketGuid
      ticketStatus
      trailerID
      weighTime
    }
    totalCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProcessLotsForDateGQL extends Apollo.Query<GetProcessLotsForDateQuery, GetProcessLotsForDateQueryVariables> {
    override document = GetProcessLotsForDateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSettingOfficialCountSourceDocument = gql`
    query GetSettingOfficialCountSource {
  settingOfficialCountSource
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSettingOfficialCountSourceGQL extends Apollo.Query<GetSettingOfficialCountSourceQuery, GetSettingOfficialCountSourceQueryVariables> {
    override document = GetSettingOfficialCountSourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTicketsForManufactureDateDocument = gql`
    query GetTicketsForManufactureDate($input: DateTime!) {
  ticketsForManufactureDate(date: $input) {
    ticketNumber
    guid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTicketsForManufactureDateGQL extends Apollo.Query<GetTicketsForManufactureDateQuery, GetTicketsForManufactureDateQueryVariables> {
    override document = GetTicketsForManufactureDateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProcessLotManufactureDateDocument = gql`
    mutation UpdateProcessLotManufactureDate($input: UpdateProcessLotManufactureDateRequestInput!) {
  updateManufactureDate(request: $input) {
    actualAverageWeight
    catchCount
    defects
    deliveryModuleCount
    delivery {
      id
    }
    doaValue
    doaType
    growerLot
    guid
    hangCount
    line
    lastOnLineToday
    manufactureDate
    originalManufactureDate
    officialCount
    officialCountSource
    processingStarted
    processingFinished
    processLotId
    processingSequence
    queued
    siteGuid
    status
    ticketNumber
    ticketGuid
    ticketStatus
    trailerID
    weighTime
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProcessLotManufactureDateGQL extends Apollo.Mutation<UpdateProcessLotManufactureDateMutation, UpdateProcessLotManufactureDateMutationVariables> {
    override document = UpdateProcessLotManufactureDateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProcessLotDocument = gql`
    mutation UpdateProcessLot($input: UpdateProcessLotStatusRequestInput!) {
  updateProcessLot(request: $input) {
    actualAverageWeight
    catchCount
    defects
    deliveryModuleCount
    delivery {
      id
    }
    doaType
    doaValue
    growerLot
    guid
    hangCount
    line
    lastOnLineToday
    manufactureDate
    originalManufactureDate
    officialCount
    officialCountSource
    processingStarted
    processingFinished
    processLotId
    processingSequence
    queued
    siteGuid
    status
    ticketNumber
    ticketGuid
    ticketStatus
    trailerID
    weighTime
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProcessLotGQL extends Apollo.Mutation<UpdateProcessLotMutation, UpdateProcessLotMutationVariables> {
    override document = UpdateProcessLotDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAttributeDocument = gql`
    mutation UpdateAttribute($input: UpdateAttributeRequestInput!) {
  attribute(request: $input) {
    guid
    manufactureDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAttributeGQL extends Apollo.Mutation<UpdateAttributeMutation, UpdateAttributeMutationVariables> {
    override document = UpdateAttributeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }