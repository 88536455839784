import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {formatDate} from "@angular/common";
import {DateTime, Duration} from "luxon";
import {TimeRange} from "../interfaces/time-range";

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {
  constructor(
    @Inject(LOCALE_ID) private locale: string
  ) {
  }

  /**
   * Transforms Date to string formatted date in yyyy-MM-dd
   * @param date
   */
  transformDate(date: Date): string {
    return formatDate(date, 'yyyy-MM-dd', this.locale)
  }

  /**
   * Get current date
   */
  getCurrentDate() {
    return DateTime.now().toJSDate();
  }

  /**
   * Strip time from Date
   */
  stripTime(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  /**
   * Return process lot duration in minutes
   * @param startDate
   * @param endDate
   */
  getDuration(startDate: Date, endDate: Date): Duration {
    const startDateTime = DateTime.fromJSDate(new Date(startDate))
    const endDateTime = DateTime.fromJSDate(new Date(endDate));

    return endDateTime.diff(startDateTime);
  }


  /**
   * Get Index from Time Range
   * @param timeRange
   * @param currentDate
   */
  getDateIndexFromTimeRange(timeRange: TimeRange[], currentDate: Date) {
    return timeRange.findIndex(
      (range: TimeRange) =>
        currentDate > range.startDate && currentDate < range.endDate
    )
  }

  /**
   * Subtract minutes from time using Luxon
   * @param date
   * @param duration
   */
  subtractMinutesFromTime(date: Date, duration: Duration): Date {
    const dateTime = DateTime.fromJSDate(new Date(date));
    return dateTime.minus(duration).toJSDate();
  }
}
