import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AbstractControl, AsyncValidatorFn, FormBuilder, ValidationErrors, Validators} from "@angular/forms";
import {Observable, of} from "rxjs";
import {CountType} from "../../../core/services/live-receiving.swagger";

export interface DialogData {
  flockCount: number;
  doaCount: number;
  doaType: CountType;
}

@Component({
  selector: 'app-edit-count-doa',
  templateUrl: './edit-count-doa.component.html',
  styleUrls: ['./edit-count-doa.component.scss']
})
export class EditCountDoaComponent implements OnInit {
  doaCountForm = this.fb.group({
    doaCount: [0, [Validators.required, Validators.min(0)]],
    doaType: ['']
  })

  constructor(
    public dialogRef: MatDialogRef<EditCountDoaComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  timeoutHandler = setInterval(() => {
  });

  onCancelClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    const max = Validators.max(this.data.flockCount);
    this.doaCountForm.controls.doaType.valueChanges.subscribe(type => {
      if (type === CountType.Count) {
        this.doaCountForm.controls.doaCount.addValidators(max);
        this.doaCountForm.controls.doaCount.addAsyncValidators(this.integerValidator());
      }
      else {
        this.doaCountForm.controls.doaCount.removeValidators(max);
        this.doaCountForm.controls.doaCount.clearAsyncValidators();
      }
      this.doaCountForm.controls.doaCount.updateValueAndValidity();
    });
    this.doaCountForm.patchValue({doaCount: this.data.doaCount ?? 0});
    this.doaCountForm.patchValue({doaType: this.data.doaType && this.data.doaType != CountType.Count ? "DefaultWeight" : CountType.Count});
  }

  integerValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value && !Number.isInteger(control.value)) {
        return of({notInteger: true});
      }
      return of(null);
    };
  }

  doaCountErrors(control: AbstractControl) {
    if (control.hasError('required'))
      return "Required";
    if (control.hasError('min'))
      return "The value must be greater than 0";
    if (control.hasError('max'))
      return "DOA Count must not be greater than " + this.data.flockCount;
    if (control.hasError('notInteger'))
      return "The value must be an integer";
    return "";
  }

  mousedown(value: number) {
    this.mouseup();
    this.timeoutHandler = setInterval(() => {
      let newValue = (this.doaCountForm.controls.doaCount.value ?? 0) + value;

      if (newValue < 0)
        newValue = 0;

      const overMax = this.doaCountForm.controls.doaType.value === 'Count' && newValue > this.data.flockCount;
      if (overMax)
        newValue = this.data.flockCount;

      this.doaCountForm.patchValue({doaCount: newValue});
      if (newValue == 0 || overMax)
        this.mouseup();
    }, 50);
    this.doaCountForm.controls.doaCount.markAsDirty();
  }

  mouseup() {
    clearTimeout(this.timeoutHandler);
  }
}
