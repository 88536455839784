import {Component, Input, OnInit} from '@angular/core';
import {DatePickerService} from "../../../core/services/date-picker.service";

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input() isHaulingPath: boolean = false;
  currentDate = new Date();

  constructor(
    private schedulerStateService: DatePickerService,
  ) {
  }

  ngOnInit(): void {
    this.schedulerStateService.currentScheduleDate.subscribe(date => this.currentDate = date);
  }

  changeDate(date: Date): void {
    if (!this.isHaulingPath) {
      this.schedulerStateService.setCurrentScheduleDate(date);
    } else {
      this.schedulerStateService.setCurrentScheduleDate(date, 'hauling-queue');
    }
  }
}
