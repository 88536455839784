import {Pipe, PipeTransform} from '@angular/core';
import {HeightService} from "../services/height.service";

@Pipe({
  name: 'downtimeHeight'
})
export class DowntimeHeightPipe implements PipeTransform {

  constructor(
    private heightService: HeightService
  ) {
  }

  transform(start: Date | undefined, end: Date | undefined, _: number): number {

    if (start === undefined) {
      start = new Date();
    }

    if (end === undefined) {
      end = new Date();
    }

    return this.heightService.getRangeHeightInPixels(start, end);
  }
}
