import {Component, OnInit} from '@angular/core';
import {TimelineService} from "../../../core/services/timeline.service";

const ZOOM_KEY = 'zoomValue';

@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.css']
})
export class ZoomComponent implements OnInit {
  minHeight = 30;
  maxHeight = 400;
  stepSize = 37;

  constructor(
    private _timelineService: TimelineService,
  ) {
  }

  ngOnInit(): void {
    this._timelineService.minDurationSubject.subscribe(
      (value) => {
        const newValue = value ? Math.ceil(300000 / value) : 1;
        const newHeight = newValue < 200 ? 200 : newValue;
        if (newHeight * 2 != this.maxHeight) {
          this.maxHeight = newHeight * 2;
          this.stepSize = (this.maxHeight - this.minHeight) / 10;
        }
      }
    );
    const savedZoomValue = localStorage.getItem(ZOOM_KEY);
    if (savedZoomValue) {
      this.handleZoom(+savedZoomValue);
    }
  }

  get timeHeight() {
    return this._timelineService.timeHeight;
  }

  /**
   * Zoom out to min height
   */
  zoomOut() {
    this.handleZoom(this.minHeight);
  }

  /**
   * Zoom in to max height
   */
  zoomIn() {
    this.handleZoom(this.maxHeight);
  }

  /**
   * Set time Height and render items again
   * @param value
   */
  handleZoom(value: number | null) {
    if (value) {
      this.checkMaxValue(value);
      this._timelineService.timeHeight = value;
      localStorage.setItem(ZOOM_KEY, value.toString());
    }
  }

  checkMaxValue(value: number) {
    if (value > this.maxHeight)
      this.maxHeight = value;
  }
}
