export function copyObject<T>(obj: T): T {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime()) as any;
  }

  if (Array.isArray(obj)) {
    const copiedArray = obj.map((item) => copyObject(item));
    return copiedArray as any;
  }

  if (typeof obj === 'object') {
    const copiedObj: any = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        copiedObj[key] = copyObject(obj[key]);
      }
    }
    return copiedObj as T;
  }

  throw new Error("Unable to copy object. Type not supported.");
}
