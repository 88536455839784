import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusTextComponent } from './status-text.component';
import {CoreModule} from "../../core/core.module";



@NgModule({
    declarations: [
        StatusTextComponent
    ],
    exports: [
        StatusTextComponent
    ],
  imports: [
    CommonModule,
    CoreModule
  ]
})
export class StatusTextModule { }
