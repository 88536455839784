import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineTimeComponent } from './line-time.component';



@NgModule({
    declarations: [
        LineTimeComponent
    ],
    exports: [
        LineTimeComponent
    ],
    imports: [
        CommonModule
    ]
})
export class LineTimeModule { }
