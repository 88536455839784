import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";
import {DateTimeService} from "./date-time.service";
import {DateTime} from "luxon";

@Injectable({
  providedIn: 'root'
})
export class DatePickerService {
  // Use luxon to set the beginning of the manufacture date to local DateTime
  currentDateTime = DateTime.now().startOf('day').toLocal();
  currentScheduleDate = new BehaviorSubject<Date>(this.currentDateTime.toJSDate());

  constructor(
    private dateTimeService: DateTimeService,
    private router: Router
  ) {
  }

  /**
   * Set the current schedule date
   *
   * @param date
   * @returns void
   */
  setCurrentScheduleDate(date: Date, path: string = 'schedule'): void {
    this.currentScheduleDate.next(date);
    const currentDate = this.dateTimeService.transformDate(this.currentScheduleDate.value);
    const params = [
      path,//could be 'schedule' or 'hauling-queue'
      currentDate
    ]
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      void this.router.navigate(params, {replaceUrl: true}));
  }

  setDateFromURL(dateString: string) {
    let [year, month, day] = dateString.split('-');
    if(!isNaN(Number(year)) && !isNaN(Number(month)) && !isNaN(Number(day))){
      let date = new Date(Number(year), Number(month)-1, Number(day));
      this.currentScheduleDate.next(date);
    }
  }
}
