import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlockCardComponent } from './flock-card.component';
import {MatCardModule} from "@angular/material/card";
import { StatusBarComponent } from './status-bar/status-bar.component';
import { DragComponent } from './drag/drag.component';
import {MatIconModule} from "@angular/material/icon";
import {StatusDotModule} from "../status-dot/status-dot.module";
import {FlockIdModule} from "../flock-id/flock-id.module";
import {PercentageBarModule} from "../percentage-bar/percentage-bar.module";
import {StatusTextModule} from "../status-text/status-text.module";
import {LineTimeModule} from "../line-time/line-time.module";
import {ChipListModule} from "./chip-list/chip-list.module";
import {FlockCardOverlayModule} from "./flock-card-overlay/flock-card-overlay.module";
import {CoreModule} from "../../core/core.module";
import {DragDropModule} from "@angular/cdk/drag-drop";



@NgModule({
  declarations: [
    FlockCardComponent,
    StatusBarComponent,
    DragComponent
  ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        StatusDotModule,
        FlockIdModule,
        PercentageBarModule,
        StatusTextModule,
        LineTimeModule,
        ChipListModule,
        FlockCardOverlayModule,
        CoreModule,
        DragDropModule
    ],
  exports: [
    FlockCardComponent
  ]
})
export class FlockCardModule { }
