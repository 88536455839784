import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SchedulePageComponent} from "./pages/schedule-page/schedule-page.component";
import {ReportsComponent} from "./pages/reports/reports.component";
import {HaulingQueuePageComponent} from "./pages/hauling-queue-page/hauling-queue-page.component";

const routes: Routes = [
  {path: '', pathMatch: 'full', component: SchedulePageComponent},
  {path: 'schedule', component: SchedulePageComponent},
  {path: 'dashboard/:id', loadComponent: () => import('@cat2/meta-cat2/reporting').then(m => m.DevExpressDashboardViewerComponent)},
  {path: 'schedule/:date', component: SchedulePageComponent},
  {path: 'hauling-queue', component: HaulingQueuePageComponent},
  {path: 'hauling-queue/:date', component: HaulingQueuePageComponent},
  {path: 'reports', pathMatch: 'full', component: ReportsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
