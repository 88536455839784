import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PaginatorService {

  currentPage: number = 0;
  pageSize: number = 10;
  sortBy: string = '';
  sortType: string = '';
  totalItems: number = 0;
  description?: string = '';
  direction?: string = '';
  updatedValues: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  paginationBackUp?: any[];

  saveCurrentValues() {
    this.paginationBackUp = [this.currentPage, this.pageSize, this.totalItems, this.description, this.direction];
  }

  restoreSavedOrDefaultValues() {
    if (this.paginationBackUp) {
      this.currentPage = this.paginationBackUp[0];
      this.pageSize = this.paginationBackUp[1];
      this.totalItems = this.paginationBackUp[2];
      this.description = this.paginationBackUp[3];
      this.direction = this.paginationBackUp[4];
      this.paginationBackUp = undefined;
      return;
    }
    // Or load default values
    this.currentPage = 0;
    this.pageSize = 10;
    this.totalItems = 0;
    this.description = '';
    this.direction = '';
  }
}
