import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatCardModule} from "@angular/material/card";
import {TimeTableToolbarComponent} from "./time-table-toolbar.component";
import { FilterButtonComponent } from './filter-button/filter-button.component';
import {MaterialModule} from "src/app/shared/material/material.module";
import {ZoomComponent} from "./zoom/zoom.component";
import {DatePickerComponent} from "./date-picker/date-picker.component";
import {TodayComponent} from "./today/today.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import { MergeComponent } from './merge/merge.component';
import { NowButtonComponent } from './now-button/now-button.component';
import {LegacyMetaCatDatePickerModule} from "@cat2/legacy-meta-cat";

@NgModule({
  declarations: [
    TimeTableToolbarComponent,
    FilterButtonComponent,
    ZoomComponent,
    DatePickerComponent,
    TodayComponent,
    MergeComponent,
    NowButtonComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MaterialModule,
    MatDatepickerModule,
    MatNativeDateModule,
    LegacyMetaCatDatePickerModule
  ],
  exports: [
    TimeTableToolbarComponent
  ]
})
export class TopToolbarModule { }
