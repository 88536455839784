import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface DialogDateData {
  manufactureDate: Date;
}
@Component({
  selector: 'app-change-manufacture-date',
  templateUrl: './change-manufacture-date.component.html',
  styleUrls: ['./change-manufacture-date.component.scss']
})
export class ChangeManufactureDateComponent {
date: Date;
  constructor(
    public dialogRef: MatDialogRef<ChangeManufactureDateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDateData,
  ) {
    this.date = new Date(this.data.manufactureDate);
    this.date.setDate(this.date.getDate() + 1);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  onConfirmClick() {
    this.dialogRef.close({newDate: this.date});
  }

  changeDate(dateChanged: Date) {
    this.date = dateChanged;
  }

}
