<div class="time-table-toolbar">
  <app-today [isHaulingPath]="isHaulingPath"></app-today>
  <app-date-picker [isHaulingPath]="isHaulingPath"></app-date-picker>
  <span class="time-table-toolbar-spacer"></span>
  <ng-container *ngIf="!isHaulingPath">
    <app-now-button></app-now-button>
    <app-filter-button></app-filter-button>
    <app-merge class="margin-left"></app-merge>
    <app-zoom class="margin-left"></app-zoom>
  </ng-container>
</div>
