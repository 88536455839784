import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {Payload} from "@cat2/legacy-meta-cat/lib/shared/metadata/payload";
import {DatePickerService} from "./date-picker.service";
import {catchError, map} from "rxjs/operators";
import {
  DeleteProcessLotGQL,
  GetProcessLotsForDateGQL,
  GetProcessLotsForDateQueryVariables, LiveReceivingProcessLotSortInput,
  UpdateProcessLotGQL,
  UpdateProcessLotManufactureDateGQL
} from "../../graphql/graphql";
import {PaginatorService} from "./paginator.service";
import {DateTime} from "luxon";

@Injectable({
  providedIn: 'root'
})
export class HaulingQueueClientService {
  constructor(
    private datePickerService: DatePickerService,
    private getProcessLotsForDateGQL: GetProcessLotsForDateGQL,
    private updateProcessLotGQL: UpdateProcessLotGQL,
    private deleteProcessLotGQL: DeleteProcessLotGQL,
    private updateProcessLotManufactureDateGQL: UpdateProcessLotManufactureDateGQL,
    private paginator: PaginatorService
  ) {
  }

  readAllRecords(): Observable<Payload<any>> {
    const executionStart = new Date();
    const orderBy = this.paginator.sortBy.length ? this.paginator.sortBy : 'processingStarted';
    const sortType = this.paginator.sortType.length ? this.paginator.sortType.toUpperCase() : 'ASC';
    const args: GetProcessLotsForDateQueryVariables = {
      skip: this.paginator.currentPage * this.paginator.pageSize,
      take: this.paginator.pageSize,
      input: this.datePickerService.currentScheduleDate.value ?? new Date(),
      orderBy: [{[orderBy]: sortType} as LiveReceivingProcessLotSortInput ]
    }
    return this.getProcessLotsForDateGQL.fetch(args, {fetchPolicy: "no-cache"})
      .pipe(
        map(res => this.toPayload(res.data.processLotsForDate, executionStart)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
      );
  }

  readRecord(id: string): Observable<Payload<any>> {
    console.log('readRecord id:' + id)
    return of({} as Payload<any>);
  }

  updateRecord(id: string, userInput: any): Observable<Payload<any>> {
    const executionStart = new Date();
    if (!userInput.dateChanged) {
      return this.updateProcessLotGQL.mutate({
        input: {
          processLotGuid: id,
          hangState: userInput.status,
          startHangTime: userInput.processingStarted,
          endHangTime: userInput.processingFinished,
          line: userInput.line,
          officialCountSource: userInput.officialCountSource,
          officialCount: userInput.officialCount,
          catchCount: userInput.catchCount,
          doaCount: userInput.doaValue,
          hangCount: userInput.hangCount,
        }
      }).pipe(
        map((res: any) => this.toPayload(res.data.updateProcessLot, executionStart)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
      );
    } else {
      return this.updateProcessLotManufactureDateGQL.mutate({
        input: {
          processLotGuid: id,
          hangState: userInput.status,
          line: userInput.line,
          manufactureDate: userInput.manufactureDate
        }
      }).pipe(
        map((res: any) => this.toPayload(res.data.updateProcessLot, executionStart)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
      );
    }
  }

  createClass(item: any): Observable<Payload<any>> {
    console.log(item)
    return of();
  }

  deleteRecord(guid: string): Observable<Payload<boolean>> {
    const executionStart = new Date();
    return this.deleteProcessLotGQL.mutate({input: guid})
      .pipe(
        map((res: any) => this.toPayload(res.data.deleteProcessLot, executionStart)),
        catchError((err) => of(this.toPayload(undefined, executionStart, err.toString())))
      );
  }

  private toPayload(processLotsForDate: any, executionStart: Date, errorMsg?: string): Payload<any> {
    const items = processLotsForDate?.items;
    if (processLotsForDate?.totalCount) {
      this.paginator.totalItems = processLotsForDate?.totalCount ?? 0;
      this.paginator.updatedValues.next(true);
    }

    return {
      data: Array.isArray(items) ? items.map(i => this.parseId(i)) : this.parseId(items),
      txGuid: '',
      executionStart: executionStart,
      executionEnd: new Date(),
      statusMessage: errorMsg ? errorMsg : 'ok',
      status: errorMsg ? 2 : 0,
    }
  }

  private parseId(item: any) {
    if (item) {
      return {
        ...item,
        __id: item.guid,
        id: item.delivery?.id,
        growerDescription: item.grower?.description,
        growerReference: item.grower?.growerReference,
        farmName: item.farm?.farmName,
        flockId: item.delivery?.flock?.flockId,
        hatchDate: item.delivery?.flock?.hatchDate,
        age: Math.floor(DateTime.now().diff(DateTime.fromJSDate(item.delivery?.flock?.hatchDate ? new Date(item.delivery?.flock?.hatchDate) : new Date()), 'days').days),
        weightExpected: item.delivery?.flock?.weightExpected,
        breed: item.delivery?.flock?.breed,
        gender: item.delivery?.flock?.gender
      }
    }
  }
}
