import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DatePickerService} from "../../core/services/date-picker.service";

@Component({
  selector: 'app-hauling-queue-page',
  templateUrl: './hauling-queue-page.component.html',
  styleUrls: ['./hauling-queue-page.component.scss']
})
export class HaulingQueuePageComponent {
  date: string | null = '';

  constructor(
    private route: ActivatedRoute,
    private datePickerService: DatePickerService
  ) {
    this.date = this.route.snapshot.paramMap.get('date');
    if(this.date){
      this.datePickerService.setDateFromURL(this.date);
    }
  }
}
