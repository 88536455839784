import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentTimeMarkerComponent } from './current-time-marker.component';
import {MatTooltipModule} from "@angular/material/tooltip";



@NgModule({
    declarations: [
        CurrentTimeMarkerComponent
    ],
    exports: [
        CurrentTimeMarkerComponent
    ],
    imports: [
        CommonModule,
        MatTooltipModule
    ]
})
export class CurrentTimeMarkerModule { }
