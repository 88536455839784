import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PercentageBarComponent } from './percentage-bar.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";



@NgModule({
    declarations: [
        PercentageBarComponent
    ],
    exports: [
        PercentageBarComponent
    ],
  imports: [
    CommonModule,
    MatProgressBarModule
  ]
})
export class PercentageBarModule { }
