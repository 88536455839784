<app-flyout></app-flyout>
<cat2-app-shell
  [disableApps]="true"
  [disableNotifications]="true"
  [disableSearch]="true"
  [desktopNavVisible]="true"
  [mobileNavVisible]="false"
>
  <router-outlet>
  </router-outlet>
</cat2-app-shell>
