import {Lines} from "../services/live-receiving.swagger";

export function compareLines(a: Lines, b: Lines) {
  if (a.line < b.line) {
    return -1;
  }
  if (a.line > b.line) {
    return 1;
  }
  // a must be equal to b
  return 0;
}
