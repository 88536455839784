<div class="dialog-container">
  <mat-dialog-content>
  <h2 class="fw-bold">Select Ticket</h2>
  <mat-form-field >
    <mat-label>Choose an option</mat-label>
    <mat-select [formControl]="formField">
      <mat-option *ngFor="let ticket of tickets" [value]="ticket">
        {{ticket.ticketNumber}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="onConfirmClick()" [disabled]="!this.formField.value">Confirm</button>
    <button mat-raised-button color="accent" (click)="onCancelClick()">Cancel</button>
  </div>
  </mat-dialog-content>
</div>
