import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {LiveReceivingProcessLot} from "./live-receiving.swagger";
import {prompt_cannotDeselectBase, prompt_confirmJoinBase, prompt_selectJoins} from "../../shared/strings";
import {Cat2DialogService, Cat2NotificationService} from "@cat2/legacy-meta-cat";
import * as lodash from "lodash";
import {ProcessLotService} from "./process-lot.service";

@Injectable({
  providedIn: 'root'
})
export class MergeService {

  selectedLots!: LiveReceivingProcessLot[];
  activeLot?: LiveReceivingProcessLot;
  isJoining!: boolean;

  constructor(
    private _dialog: Cat2DialogService,
    private _notification: Cat2NotificationService,
    private _processLotService: ProcessLotService
  ) { }

  set mergeSubject$(value: BehaviorSubject<boolean>) {
    this._mergeSubject$ = value;
  }
  get mergeSubject$(): BehaviorSubject<boolean> {
    return this._mergeSubject$;
  }
  private _mergeSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  set selectedLotsSubject$(value: BehaviorSubject<LiveReceivingProcessLot[]>) {
    this._selectedLotsSubject$ = value;
  }
  get selectedLotsSubject$(): BehaviorSubject<LiveReceivingProcessLot[]> {
    return this._selectedLotsSubject$;
  }
  private _selectedLotsSubject$: BehaviorSubject<LiveReceivingProcessLot[]> = new BehaviorSubject<LiveReceivingProcessLot[]>([]);

  /**
   * Toggle merge button of true or false
   */
  mergeToggle(value: boolean) {
    if (!value) {
      return this._mergeSubject$.next(false)
    }
    return this._mergeSubject$.next(true);
  }

  /**
   * Toggle is process lot checked value of true or false
   */
  checkedToggle(processLot:LiveReceivingProcessLot): boolean {
    return processLot.processLotId == this.activeLot?.processLotId || this.selectedLots?.find(l => l.processLotId == processLot.processLotId) != undefined;
  }

  startJoin() {
    this.mergeToggle(true);
    this.isJoining = true;
    this.selectedLots = [];
    this._selectedLotsSubject$.next(this.selectedLots);
    this._notification.info(prompt_selectJoins, 0);
  }

  selectLotForJoin(item: LiveReceivingProcessLot) {
    if (this.selectedLots.length == 0) {
      this.confirmLotBase(item);
    } else {
      this.addLotToSelection(item);
    }
  }

  confirmLotBase(item: LiveReceivingProcessLot) {
    this._dialog.ShowYesNoDialog('',
      prompt_confirmJoinBase(item.processLotId),
      (yes:boolean) => {
        if (yes) {
          this.addLotToSelection(item);
          this._notification.info(prompt_selectJoins, 0);
        } else {
          this.deselectAllLots();
        }
      }
    );
  }

  addLotToSelection(item: LiveReceivingProcessLot) {
    this.selectedLots = this.selectedLots.concat(item);
    this._selectedLotsSubject$.next(this.selectedLots);
    this.checkedToggle(item);
  }

  deselectAllLots(): void {
    this.selectedLots = [];
    this.activeLot = undefined;
  }

  deselectLotForJoin(item: LiveReceivingProcessLot) {
    if (lodash.indexOf(this.selectedLots, item) == 0) {
      this._dialog.ShowMessage(prompt_cannotDeselectBase);
    }
    else{
      lodash.remove(this.selectedLots, this.selectedLots.find((i)=>i.processLotId==item.processLotId));
      this._selectedLotsSubject$.next(this.selectedLots);
    }
  }

  updateLot(processLot: LiveReceivingProcessLot) {
    processLot.processingStarted = new Date(processLot.processingStarted);
    processLot.processingFinished = new Date(processLot.processingFinished);
    let lot = this._processLotService.processLotSubject$.value.find(
      (responseItem) => responseItem.guid == processLot.guid
    );
    if (lot) lodash.assign(lot, processLot);
    else {
      this._processLotService.processLotSubject$.value.push(processLot);
    }
  }
}
