export const prompt_selectJoins = 'All lots will be merged into the first selected lot.';
export const prompt_confirmCancelJoin =
  'Do you want to cancel the join operation?';
export function prompt_confirmJoinBase(lotId:string|undefined) {
  return `Do you want to use lot ${lotId} as the basis for this join? All subsequent lots will be added to it.`;
}
export const prompt_cannotDeselectBase =
  'This lot was selected as the basis for the merge, and cannot be deselected.';
export const prompt_canOnlySelectWaitingLot =
  'Process lots with status WAITING can only be selected for merge.';
export const prompt_confirmJoin = 'Do you want to join the selected lots?';
export function prompt_confirmSplit(lotId:string) {
  return `Do you want to split lot ${lotId} into a new lot?`;
}
export function prompt_confirmLastProcessLot(lotId:string) {
  return `Do you want to process lot ${lotId} to be the last lot for the day?
  All the process lots after this will be moved to next day.
  `;
}
export function prompt_confirmStatusToComplete(lotId: string) {
  return `Are you sure you want to mark Process Lot ${lotId} as Complete?`;
}
export function prompt_confirmDelete(lotId: string) {
  return `Are you sure you want to delete this Process Lot ${lotId}?`;
}
export function prompt_confirmPost(lotId: string) {
  return `Are you sure you want to mark Process Lot ${lotId} as Posted?`;
}
