import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'processLotIcons'
})
export class ProcessLotIconsPipe implements PipeTransform {

  transform(value: string): string {
    switch(value.toLowerCase()){
      case 'bird':
        return 'cat2-chicken.svg';
      case 'farm':
        return 'cat2-farm.svg';
      case 'grower':
        return 'cat2-grower.svg';
      case 'weight':
        return 'cat2-weight.svg';
      case 'truck':
        return 'cat2-local-shipping.svg';
      default:
        return ''
    }
  }

}
