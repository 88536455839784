import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlyoutComponent} from './flyout.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {StatusDotModule} from "../status-dot/status-dot.module";
import {StatusTextModule} from "../status-text/status-text.module";
import {PercentageBarModule} from "../percentage-bar/percentage-bar.module";
import { EditCountDoaComponent } from './edit-count-doa/edit-count-doa.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import { MatInputModule } from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatSliderModule} from "@angular/material/slider";
import {Cat2SharedModule} from "@cat2/legacy-meta-cat";
import {CoreModule} from "../../core/core.module";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";


@NgModule({

  declarations: [
    FlyoutComponent,
    EditCountDoaComponent
  ],
  exports: [
    FlyoutComponent
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatButtonModule,
    MatSidenavModule,
    MatSidenavModule,
    MatIconModule,
    StatusDotModule,
    StatusTextModule,
    PercentageBarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatProgressBarModule,
    MatSliderModule,
    Cat2SharedModule,
    CoreModule,
    ReactiveFormsModule,
    MatRadioButton,
    MatRadioGroup
  ]
})
export class FlyoutModule {
}
