import {Component, Input} from '@angular/core';
import {LiveReceivingProcessLot, ProcessLotsClient} from "../../core/services/live-receiving.swagger";

@Component({
  selector: 'app-flock-card',
  templateUrl: './flock-card.component.html',
  styleUrls: ['./flock-card.component.scss'],
  providers: [ProcessLotsClient]
})
export class FlockCardComponent {
  @Input() processLot!: LiveReceivingProcessLot;
  @Input() isFiltered = false;
  @Input() onPreview = false;

  get canDrag() {
    return this.processLot.status == 'SCHEDULED' || this.processLot.status == 'WAITING';
  }
}
