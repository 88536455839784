import {Pipe, PipeTransform} from '@angular/core';
import {HeightService} from "../services/height.service";
import {DateTimeService} from "../services/date-time.service";
import {Shift} from "../services/live-receiving.swagger";

@Pipe({
  name: 'shiftTransform'
})
export class ShiftTransformPipe implements PipeTransform {

  constructor(
    private heightService: HeightService,
    private dateTimeService: DateTimeService
  ) {
  }

  transform(
    shift: Shift,
    timeHeight: number,
    startDateTime:Date
  ): number {
    // Get total pixels to be translated
    return this.heightService.getRangeHeightInPixels(
      startDateTime,
      shift.startDateTime
    );
  }

}
