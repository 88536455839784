import {NgModule} from '@angular/core';
import {SchedulePageComponent} from "./schedule-page/schedule-page.component";
import {FlockCardModule} from "../features/flock-card/flock-card.module";
import {StatusDotModule} from "../features/status-dot/status-dot.module";
import {TopToolbarModule} from "../features/top-toolbar/top-toolbar.module";
import {DayTimelineModule} from "../features/day-timeline/day-timeline.module";
import {FilterBarModule} from "../features/filter-bar/filter-bar.module";
import {LineBarModule} from "../features/line-bar/line-bar.module";
import {CurrentTimeMarkerModule} from "../features/current-time-marker/current-time-marker.module";
import {FlyoutModule} from "../features/flyout/flyout.module";
import {CommonModule} from "@angular/common";
import {ReportsComponent} from './reports/reports.component';
import {Cat2PowerBiReportModule} from "@cat2/legacy-meta-cat";
import {HaulingQueuePageComponent} from './hauling-queue-page/hauling-queue-page.component';
import {HaulingQueueGridModule} from "../features/hauling-queue-grid/hauling-queue-grid.module";


@NgModule({
  declarations: [
    SchedulePageComponent,
    ReportsComponent,
    HaulingQueuePageComponent
  ],
  imports: [
    FlockCardModule,
    StatusDotModule,
    TopToolbarModule,
    DayTimelineModule,
    FilterBarModule,
    LineBarModule,
    CurrentTimeMarkerModule,
    FlyoutModule,
    CommonModule,
    Cat2PowerBiReportModule,
    HaulingQueueGridModule
  ],
  exports: []
})
export class PagesModule {
}
