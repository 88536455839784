<button
  mat-icon-button
  matTooltip="Zoom out"
  (click)="zoomOut()"
  class="v-align-middle"
>
  <mat-icon>zoom_out</mat-icon>
</button>
<mat-slider
  [min]="minHeight"
  [max]="maxHeight"
  [step]="stepSize"
 #ngSlider><input matSliderThumb [value]="timeHeight" (input)="handleZoom({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value}.value)" #ngSliderThumb="matSliderThumb" /></mat-slider>
<button
  mat-icon-button
  matTooltip="Zoom in"
  (click)="zoomIn()"
  class="v-align-middle"
>
  <mat-icon>zoom_in</mat-icon>
</button>
