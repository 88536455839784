import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {LiveReceivingProcessLot, ProcessLotsClient, UpdateProcessLotLineRequest} from "./live-receiving.swagger";
import {Cat2NotificationService} from "@cat2/legacy-meta-cat";
import {DatePickerService} from "./date-picker.service";
import {copyObject} from "../helpers/copy-object";

@Injectable({
  providedIn: 'root'
})
export class ProcessLotService {
  private _processLotsSubject$: BehaviorSubject<LiveReceivingProcessLot[]> = new BehaviorSubject<LiveReceivingProcessLot[]>([]);

  get processLotSubject$(): BehaviorSubject<LiveReceivingProcessLot[]> {
    return this._processLotsSubject$;
  }

  constructor(
    private processLotClient: ProcessLotsClient,
    private schedulerStateService: DatePickerService,
    private cat2NotificationService: Cat2NotificationService
  ) {
  }


  /**
   * Update the process lot behavior subject based on the changed lots from update API
   * @param processLots
   */
  updateProcessBehaviorSubject(processLots: LiveReceivingProcessLot[]) {
    if (processLots.length > 0) {
      this.processLotSubject$.value.forEach(
        (subjectLot: LiveReceivingProcessLot) => {
          const changedLots = processLots.find(
            (lot: LiveReceivingProcessLot) => subjectLot.guid === lot.guid
          );
          if (changedLots) {
            //this.dateTimeService.convertStringIsoToDateInObj(changedLots);
            Object.assign(subjectLot, {...changedLots})
          }
        }
      )
    }
    this._processLotsSubject$.next(copyObject(this.processLotSubject$.value));
  }

  /**
   * Pass updated process lot to the API and get back updated lots
   * Endpoint: /api/ProcessLots/UpdatePosition
   * @param processLot
   */
  updateProcessLotPosition(processLot: LiveReceivingProcessLot) {
    this.processLotClient.updatePosition(
      UpdateProcessLotLineRequest.fromJS({
        processLotGuid: processLot.guid,
        line: processLot.line,
        processingStartDateTime: processLot.processingStarted
      })
    ).subscribe({
      next: lots => {
        console.log(lots)
        this.updateProcessBehaviorSubject(lots.data)
      },
      error: error => {
        this.cat2NotificationService.error('Unable to move process lot: ' + error);
      }
    })
  }

  async updateProcessLotPositionAsync(processLot: LiveReceivingProcessLot) {
    await this.processLotClient.updatePosition(
      UpdateProcessLotLineRequest.fromJS({
        processLotGuid: processLot.guid,
        line: processLot.line,
        processingStartDateTime: processLot.processingStarted
      })
    ).toPromise();
  }
}
