import {Injectable} from '@angular/core';
import {GetTicketsForManufactureDateGQL, UpdateAttributeGQL, UpdateAttributeRequestInput} from "../../graphql/graphql";

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  constructor(
    private getTicketsForManufactureDateGQL: GetTicketsForManufactureDateGQL,
    private updateAttributeGQL: UpdateAttributeGQL
  ) {
  }

  getTicketsForManufactureDate(date: Date) {
    return this.getTicketsForManufactureDateGQL.watch({input: date}).valueChanges;
  }

  updateAttribute(input: UpdateAttributeRequestInput) {
    return this.updateAttributeGQL.mutate({input: input});
  }
}
