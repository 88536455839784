import {Component, Input, OnInit} from '@angular/core';
import {Shift} from "../../core/services/live-receiving.swagger";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.scss'],
  providers: [DatePipe]
})
export class ShiftComponent implements OnInit {
  @Input() shift!: Shift

  shiftStart: string | null | undefined;
  shiftEnd: string | null | undefined;

  constructor(
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.shiftStart = this.datePipe.transform(this.shift.startDateTime, 'shortTime');
    this.shiftEnd = this.datePipe.transform(this.shift.endDateTime, 'shortTime');
  }

}
