import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlockCardOverlayComponent } from './flock-card-overlay.component';
import {MatIconModule} from "@angular/material/icon";



@NgModule({
    declarations: [
        FlockCardOverlayComponent
    ],
    exports: [
        FlockCardOverlayComponent
    ],
  imports: [
    CommonModule,
    MatIconModule
  ]
})
export class FlockCardOverlayModule { }
