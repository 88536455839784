<button
  mat-icon-button
  aria-label="Merge"
  matTooltip="Merge"
  class="v-align-middle"
  *ngIf="!isJoining"
  (click)="startJoin()"
>
  <mat-icon>merge_type</mat-icon>
</button>
<button
  matTooltip="Accept Join"
  *ngIf="isJoining && selectedLots!.length > 1"
  mat-icon-button
  (click)="joinLots()"
>
  <mat-icon>check</mat-icon>
</button>
<button
  matTooltip="Cancel Join"
  *ngIf="isJoining"
  mat-icon-button
  (click)="cancelJoin()"
>
  <mat-icon>close</mat-icon>
</button>

