import { Component, OnInit } from '@angular/core';
import {FilterService} from "../../../core/services/filter.service";

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent implements OnInit {

  constructor(
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
  }
  onClick(){
    this.filterService.toggle();
  }

}
